import {Service} from '@/models/service';
import {request} from './index';

export const getUser = async (): Promise<Service> => request('GET', '/user/');

export const login = async (data: {}) => request('POST', '/user/login/', {
    data,
});

export const editJob = async (job: string): Promise<Service> => request('POST', '/user/position/', {
    data: {
        position: job,
    },
});

export const changePassword = async (data: object) => request(
    'POST',
    '/user/',
    { data }
);

export const searchStudents = async (name: string):
  Promise<Service> => request('GET', `/user/student/search/?keyword=${name}`);


export const getMessages = async (params: object): Promise<Service> => request(
    'GET',
    `/user/message/`,
    { params }
);
