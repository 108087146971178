import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Table,
  Modal,
  Input,
  notification,
  Popconfirm,
  Drawer,
  Upload,
  Typography
} from 'antd';
import '@/pages/Main/MyDeliver/index.less';
import { getAnnounces, changeAnnounce, createAnnounce, removeAnnounce } from '@/service/superuser'
import { upLoadFile } from '@/service/cos'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor';


const CollegeManager: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [docVisible, setDocVisible] = useState<boolean>(false);
  const [createVisible, setCreateVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);
  const [data, setData] = useState<Array<any>>([]);
  const [curData, setCurData] = useState<any>();
  const [choices, setChoices] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>();
  const [changeForm] = Form.useForm()
  const [createForm] = Form.useForm()
  const onLoad = async () => {
    await getData({})
  };
  useEffect(() => {
    onLoad();
  }, []);
  const [columns, setColumns] = useState([])

  const UpLoadProps = {
    name: 'file',
    multiple: false,
    fileList: [],
    customRequest: async (info: any) => {
      const { file } = info;
      const url: string = await upLoadFile(file, 'announce');
      notification.info({message: '上传成功'})
      setCurData({
        ...curData,
        doc: url
      })
    },
    accept: '.doc,.docx,.pdf'
  };

  const AttachUpLoadProps = {
    name: 'file',
    multiple: false,
    fileList: [],
    customRequest: async (info: any) => {
      const { file } = info;
      const url: string = await upLoadFile(file, 'announce');
      notification.info({message: '上传成功'})
      setCurData({
        ...curData,
        attach: url
      })
    },
    accept: '*'
  };


  const getData = async (params: object) => {
    setLoading(true)
    const { data } = await getAnnounces({
      page,
      page_size,
      ...params
    })
    setData(data.list)
    setTotal(data.total)
    setChoices(data.choices)
    setColumns(data.columns.concat([
      {
        title: '操作',
        render: (_: any, record: any) => (
          <Space size="middle">
            <a onClick={() => {
              setVisible(true)
              changeForm.resetFields()
              changeForm.setFieldsValue(record)
              setCurData({
                ...record,
                content: BraftEditor.createEditorState(record.content)
              })
            }}>编辑</a>
            <a onClick={() => {
              setDocVisible(true)
              setCurData(record)
            }}>预览</a>
            <Popconfirm
              title="确认要删除该行数据吗？"
              onConfirm={() => remove({announce_id: record.id})}
              okText="是"
              cancelText="否"
            >
              <a href="#" style={{color: '#dc2c2c'}}>删除</a>
            </Popconfirm>
          </Space>
        )
      },
    ]))
    setLoading(false)
  }

  const change = async (values: any) => {
    values = {
      ...values,
      attach: curData?.attach,
      content: curData?.content.toHTML() || '',
      doc: curData?.doc,
    }
    for (let key in values) {
      if (values[key] === undefined){
        values[key] = null
      }
    }
    console.log(values);
    const { data } = await changeAnnounce({ ...values, announce_id: curData.id })
    notification.success({message: data})
    setVisible(false)
    getData({})
  }

  const create = async (values: any) => {
    values = {
      ...values,
      attach: curData?.attach,
      content: curData?.content || '',
      doc: curData?.doc,
    }
    for (let key in values) {
      if (values[key] === undefined){
        values[key] = null
      }
    }
    console.log(values);
    const { data } = await createAnnounce(values)
    notification.success({message: data})
    setCreateVisible(false)
    createForm.resetFields()
    getData({})
  }

  const remove = async (values: any) => {
    const { data } = await removeAnnounce(values)
    notification.success({message: data})
    setCreateVisible(false)
    createForm.resetFields()
    getData({})
  }

  return (
    <div>
      <Drawer
        visible={docVisible}
        onClose={() => setDocVisible(false)}
        placement="right"
        width='50%'
        closable={false}
      >
        <Typography.Title style={{textAlign: 'center'}} level={3}>
          {curData?.title || '???'}
        </Typography.Title>
        <Typography.Title level={5} style={{textAlign: 'center', color: '#777'}}>
          {curData?.creator || '???'} 发布于 {curData?.created_at || '???'}
        </Typography.Title>
        <Row justify='space-between' style={{margin: 16}}>
          <Col>
            阅读量: {curData?.view}
          </Col>
          <Col>
            {
              curData?.attach && <a href={curData.attach} target='_blank'>下载附件</a>
            }
          </Col>
        </Row>
        {
          curData?.type === 'text' ? <div
              className="braft-output-content"
              dangerouslySetInnerHTML={{__html: curData?.content}}
            />
            : <iframe
              style={{width: '100%', minHeight: '85vh', border: 'none'}}
              src={`${curData?.doc}?ci-process=doc-preview&dstType=html`}
            />
        }
      </Drawer>
      <Modal
        title='修改信息'
        visible={visible}
        onCancel={() => setVisible(false)}
        mask={true}
        footer={null}
        width='80vw'
      >
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} name="change" onFinish={change} form={changeForm}>
          {
            columns?.map((item: any) => item?.edit && <Form.Item name={item.name || item.dataIndex} label={item.title}>
              {
                item?.select ? <Select
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    choices[item.dataIndex]?.map((item: any) => <Select.Option
                      value={item.value}
                    >{item.label}</Select.Option>)
                  }
                </Select> : <Input style={{marginRight: '16px'}} placeholder={`请输入${item.title}`}/>
              }
            </Form.Item>)
          }
          <Form.Item label="文档" name='doc'>
            <Row style={{alignItems: 'center'}}>
              <Upload {...UpLoadProps} >
                <Button icon={<UploadOutlined />}>{
                  curData?.doc ? '重新上传' : '上传公告'
                }</Button>
              </Upload>
              {
                curData?.doc && <a target='_blank' style={{marginLeft: '12px'}} href={`${curData?.doc}?ci-process=doc-preview&dstType=html`}>预览</a>
              }
            </Row>
          </Form.Item>
          <Form.Item label="附件" name='attach'>
            <Row style={{alignItems: 'center'}}>
              <Upload {...AttachUpLoadProps} >
                <Button icon={<UploadOutlined />}>{
                  curData?.attach ? '重新上传' : '上传附件'
                }</Button>
              </Upload>
              {
                curData?.attach && <a target='_blank' style={{marginLeft: '12px'}} href={curData?.attach}>查看</a>
              }
            </Row>
          </Form.Item>
          <Form.Item label="在线编辑">
            <div>
              <BraftEditor
                className="editor-component"
                value={curData?.content}
                onChange={(editState) => {
                  console.log(curData);
                  setCurData({
                    ...curData,
                    content: editState
                  });
                }}
              />
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='新增'
        visible={createVisible}
        onCancel={() => setCreateVisible(false)}
        mask={true}
        footer={null}
        width='80vw'
      >
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} name="change" onFinish={create} form={createForm}>
          {
            columns?.map((item: any) => item?.edit && <Form.Item name={item.name || item.dataIndex} label={item.title}>
              {
                item?.select ? <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    choices[item.dataIndex]?.map((item: any) => <Select.Option
                      value={item.value}
                    >{item.label}</Select.Option>)
                  }
                </Select> : <Input style={{marginRight: '16px'}} placeholder={`请输入${item.title}`}/>
              }
            </Form.Item>)
          }
          <Form.Item label="文档" name='doc'>
            <Row style={{alignItems: 'center'}}>
              <Upload {...UpLoadProps} >
                <Button icon={<UploadOutlined />}>{
                  curData?.doc ? '重新上传' : '上传公告'
                }</Button>
              </Upload>
              {
                curData?.doc && <a target='_blank' style={{marginLeft: '12px'}} href={`${curData?.doc}?ci-process=doc-preview&dstType=html`}>预览</a>
              }
            </Row>
          </Form.Item>
          <Form.Item label="附件" name='attach'>
            <Row style={{alignItems: 'center'}}>
              <Upload {...AttachUpLoadProps} >
                <Button icon={<UploadOutlined />}>{
                  curData?.attach ? '重新上传' : '上传附件'
                }</Button>
              </Upload>
              {
                curData?.attach && <a target='_blank' style={{marginLeft: '12px'}} href={curData?.attach}>查看</a>
              }
            </Row>
          </Form.Item>
          <Form.Item label="在线编辑" name='content'>
            <div>
              <BraftEditor
                className="editor-component"
                value={curData?.content}
                onChange={(editState) => {
                  setCurData({
                    ...curData,
                    content: editState
                  });
                }}
              />
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row
        gutter={10}
        justify="center"
        style={{
          marginTop: 20,
        }}
      >
        <Col span={23}>
          <Card>
            <Row justify='space-between' style={{margin: '16px 0'}}>
              <Col>
                <Input.Search placeholder="搜索相关内容" onSearch={(keyword: any) => getData({keyword})} allowClear />
              </Col>
              <Col>
                <Row justify='space-between'>
                  <Button type='primary' icon={<PlusOutlined />} onClick={() => {
                    setCreateVisible(true);
                    setCurData(null)
                    createForm.resetFields()
                  }}>
                    创建
                  </Button>
                </Row>
              </Col>
            </Row>
            <Table
              columns={columns}
              rowKey={(record: any) => record.id}
              dataSource={data}
              loading={loading}
              pagination={{
                pageSize: page_size,
                current: page,
                showSizeChanger: true,
                total: total,
                onChange: (page: number, page_size: number) => {
                  setPage(page)
                  setPageSize(page_size)
                  getData({page, page_size})
                }
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CollegeManager;
