import React, {useEffect, useState} from 'react';
import "./index.less"
import {CheckCircleTwoTone} from '@ant-design/icons';
import {Button} from 'antd'
import {useParams} from "react-router-dom";
import {WeeklyNews} from '@/models/weeklyNews';
import {getNewsById} from '@/service/weeklyNews';

const Success: React.FC = (props) => {
    const params: any = useParams();
    const {id} = params;

    const [news, setNews] = useState<WeeklyNews>()

    const onLoad = async () => {
        const {data} = await getNewsById(id);
        setNews(data)
    }

    const handleClick = () => {
        props.history.push('/Main/WeeklyNewspaper/Home');
    }

    useEffect(() => {
        onLoad()
    }, [])
    return (
        <div className='week-success'>
            <div className='container'>
                <CheckCircleTwoTone twoToneColor="#52c41a" className='icon'/>
                <div className='title'>
                    提交成功
                </div>
                <div className='desc'>
                    <div>
                        您本次上传的实习周报成功提交到指导教师处。
                    </div>
                    <div>
                        祝实习愉快！
                    </div>
                </div>
                <div className='info'>
                    <div>实习单位：{news?.company}</div>
                    <div>实习周次：第{news?.week}周次</div>
                    <div>备注（选填）：{news?.tip}</div>
                    <div>日期：{`${news?.start_at} ~ ${news?.end_at}`}</div>
                </div>
                <Button type="primary" className='btn' onClick={handleClick}>确定</Button>
            </div>
        </div>
    )
}

export default Success;