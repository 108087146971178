import React, { useEffect, useState } from 'react';
import { Col, PageHeader, Row, Table, Card, Select, Button, Space, message } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import '@/pages/Main/ReadOver/index.less';
import { HeaderRouter } from '@/models/router';
import { Service } from '@/models/service';
import { getStudentWeekReports } from '@/service/weekReport';
import Read from '@/pages/Main/ReadOver/component/read';
import { REPORT_STATUS_MAP } from '@/constant/index'
import { fileDownload } from '@/utils';


const { Option } = Select;

export const routes: HeaderRouter[] = [
  {
    path: '/Main/Home',
    breadcrumbName: '首页',
  },
  {
    path: '/Main/StudentManagement',
    breadcrumbName: '周报批阅',
  },
];

const grades: number[] = [0, 1, 2, 3, 4, 5].map(item => {
  return (new Date()).getFullYear() - item
})
const weeks: number[] = Array.from({length: 20}, (v, k) => k + 1)

const ReadOver: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [reports, setReports] = useState<any[]>([]);
  const [params, setParams] = useState<any>({ grade: grades[3] });
  const readRef = React.createRef<any>()

  const onLoad = async () => {
    await handleTableChange(params)
  };
  useEffect(() => {
    onLoad();
  }, []);

  const handleTableChange = async (_params: object) => {
    setLoading(true)
    setParams(_params);
    const reportsData: Service = await getStudentWeekReports(_params);
    setReports(reportsData.data.list);
    setLoading(false)
  };
  const columns = [
    {
      title: '学号',
      dataIndex: 'student_id',
    },
    {
      title: '姓名',
      dataIndex: 'student',
    },
    {
      title: '实习单位',
      dataIndex: 'company',
      width: '25%'
    },
    {
      title: '提交时间',
      dataIndex: 'submit_at',
    },
    {
      title: '周次',
      dataIndex: 'week',
      width: '15%'
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: '15%',
      render: (val: number, record: any) => (
        <span style={{color: REPORT_STATUS_MAP[val].color}}>
          {REPORT_STATUS_MAP[val].name}
          {(val === 2 || val === 4) && <span>
            {record.comment ?
              <span className='success'>（已读）</span> :
              <span className='danger'>（未读）</span>}
          </span>}
        </span>
      )
    },
    {
      title: '操作',
      dataIndex: 'id',
      width: '10%',
      render: (_: any, record: any) => (
        <Space size="middle">
          { (record.status === 2 || record.status === 4) &&
          <a onClick={() => readRef.current.show(record)}>批阅</a>}
          <a className='success' onClick={() => {
            if (!record.pdf) {
              message.info('该份周报没有上传附件')
              return
            }
            fileDownload(record.pdf)
          }}>下载</a>
        </Space>
      )
    },
  ];
  return (
    <div className="student-management">
      <Read showComment onRef={readRef} refresh={() => handleTableChange(params)}/>
      <PageHeader
        className="header"
        ghost={false}
        title="周报批阅"
        breadcrumb={{ routes }}
      />
      <Row
        gutter={10}
        justify="center"
        style={{
          marginTop: 20,
        }}
      >
        <Col span={23}>
          <Card>
            <Row justify='space-between' style={{margin: '16px 0'}}>
              <Row>
                <Row align='middle'>
                  <span style={{marginRight: 8}}>年级</span>
                  <Select value={params.grade} style={{ width: 120 }} onChange={(val) => {
                    handleTableChange({...params, grade: val})
                  }}>
                    {grades.map(grade => (
                      <Option key={grade} value={grade}>{grade}</Option>
                    ))}
                  </Select>
                </Row>
                <Row align='middle' style={{marginLeft: 16}}>
                  <span style={{marginRight: 8}}>状态</span>
                  <Select
                    value={params.status || null}
                    style={{ width: 120 }}
                    allowClear
                    placeholder='请选择状态'
                    onChange={(val) => {
                    handleTableChange({...params, status: val})
                  }}>
                    {Object.keys(REPORT_STATUS_MAP).map(key => (
                      <Option key={key} value={key}>{REPORT_STATUS_MAP[key].name}</Option>
                    ))}
                  </Select>
                </Row>
                <Row align='middle' style={{marginLeft: 16}}>
                  <span style={{marginRight: 8}}>周次</span>
                  <Select
                    value={params.week || null}
                    style={{ width: 120 }}
                    allowClear
                    placeholder='请选择周次'
                    onChange={(val) => {
                    handleTableChange({...params, week: val})
                  }}>
                    {weeks.map(item => (
                      <Option key={item} value={item}>第{item}周</Option>
                    ))}
                  </Select>
                </Row>
              </Row>
              <Col>
                <Row justify='space-between' style={{width: '200px'}}>
                  <div/>
                  <Button type="primary" icon={<CloudDownloadOutlined />}>
                    批量下载
                  </Button>
                </Row>
              </Col>
            </Row>
            <Table
              columns={columns}
              rowKey={record => record.id}
              dataSource={reports}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ReadOver;
