import React, {useEffect, useState} from 'react';
import '@/pages/Main/PersonalCenter/component/Info/index.less';
import {
    Card,
    Avatar,
    Divider,
    Button,
    Popconfirm,
    message,
    Modal,
    Form,
    Input,
} from 'antd';
import {
    UserOutlined,
    CompassOutlined,
    MailOutlined,
    PhoneOutlined,
    BarChartOutlined,
    BankOutlined,
    IdcardOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

import {editJob} from '@/service/user';
import {User} from '@/models/user';
import {delResume, getResumeInfo} from '@/service/resume';
import { setUser } from '@/store/actions/User';
import {resumeInfo} from '@/models/resume';
import ResumeModal from '@/pages/Main/PersonalCenter/component/ResumeModal';
import {refresh} from '@/utils';
import { Service } from '@/models/service';
import { connect } from 'react-redux';

interface InfoItemProps {
    _key: string;
    value?: string;
    icon: React.FC;
}

interface ResumeInfoProps {
    name: string;
    deleteParam: string;
    pdf: string;
    otherFile: string;
}

const InfoItem: React.FC<InfoItemProps> = (props) => {
    const {icon, _key, value} = props;
    const Icon: any = icon;
    const [edit, setEdit] = useState<boolean>(false);
    const [job, setJob] = useState<string>(value || '');

    const close = () => {
        setEdit(false);
    };

    const submit = async () => {
        try {
            await editJob(job);
            close();
            message.success('修改成功！');
            refresh();
        } catch (e) {
            message.error(`${e}修改失败!`);
        }
    };

    return (
        <div className="info-item">
            <Icon className="icon"/>
            <div className="text">
                {_key}
                &nbsp;&nbsp;&nbsp;
                {value}
            </div>
            {_key === '期望职位' ? (
                <Button
                    type="link"
                    className="btn"
                    onClick={() => {
                        setEdit(true);
                    }}
                >
                    编辑
                </Button>
            ) : (
                ''
            )}
            <Modal
                className="edit-modal"
                title="期望职位"
                visible={edit}
                onCancel={close}
                onOk={submit}
                cancelText="取消"
                okText="提交"
                destroyOnClose
            >
                <Form name="basic" initialValues={{job: value}}>
                    <Form.Item
                        label="期望职位"
                        name="job"
                        rules={[
                            {
                                required: true,
                                message: '请输入你期望的职位',
                            },
                        ]}
                    >
                        <Input
                            value={job}
                            placeholder="请输入你期望的职位"
                            onChange={(val) => {
                                setJob(val.target.value);
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

const ResumeInfo: React.FC<ResumeInfoProps> = function (props) {
    const {
        pdf, name, otherFile, deleteParam,
    } = props;
    return (
        <div className="info">
            <div>
                <a href={`${pdf}`} target="_blank" rel="noreferrer">
                    <IdcardOutlined/>
                    <div>{name}</div>
                </a>
                {otherFile ? (
                    <a
                        href={`${otherFile}`}
                        className="other-file"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div>其他材料</div>
                    </a>
                ) : (
                    ''
                )}
            </div>
            <Popconfirm
                title="你确定要删除你的简历吗？"
                onConfirm={() => {
                    delResume(deleteParam); // 简历id
                    refresh();
                }}
                okText="确认"
                cancelText="取消"
            >
                <Button type="link">
                    <DeleteOutlined className="del-icon"/>
                </Button>
            </Popconfirm>
        </div>
    );
};

const Info: React.FC<{ user: User }> = (props) => {
  const [userInfo, setUserInfo] = useState<User>();
  const [resumeDetail, setResumeDetail] = useState<resumeInfo>();
  const [resumeModalProp, setResumeModalProp] = useState<any>();

  const onLoad = async () => {
    const resumeData: Service = await getResumeInfo();
    setUserInfo(props.user);
    setResumeDetail(resumeData.data);
  };

    const openResumeModal = () => {
        setResumeModalProp({
            title: '上传简历',
            isShowResumeModal: true,
        });
    };

    const closeResumeModal = () => {
        setResumeModalProp({
            isShowResumeModal: false,
        });
    };

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <Card
            bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <ResumeModal {...resumeModalProp} close={closeResumeModal}/>
            <Avatar src={userInfo?.avatar} size="large" className="avatar"/>
            <div className="user-name">{userInfo?.username}</div>
            <div className="student-id">{userInfo?.student_id}</div>
            <div className="info-list">
                <InfoItem _key="年级" value={userInfo?.grade} icon={UserOutlined}/>
                <InfoItem _key="学院" value={userInfo?.college} icon={BankOutlined}/>
                <InfoItem _key="专业" value={userInfo?.major} icon={BarChartOutlined}/>
                <InfoItem _key="手机" value={userInfo?.mobile} icon={PhoneOutlined}/>
                <InfoItem _key="邮箱" value={userInfo?.email} icon={MailOutlined}/>
                <InfoItem
                    _key="期望职位"
                    value={userInfo?.expect_position}
                    icon={CompassOutlined}
                />
            </div>
            <Divider/>
            <div className="resume">
                <div className="title">我的简历</div>
                {resumeDetail?.list?.map((val: any) => (
                    <ResumeInfo
                        name={val.name}
                        deleteParam={val.id}
                        pdf={val.pdf}
                        key={val.id}
                        otherFile={val.other_file}
                    />
                ))}
                <Button type="primary" onClick={openResumeModal} className="upload-btn">
                    上传简历
                </Button>
            </div>
        </Card>
    );
};

const mapStateToProps = (state: { User: User; }) => ({ user: state.User });

const mapDispatchToProps = { setUser };

export default connect(mapStateToProps, mapDispatchToProps)(Info);
