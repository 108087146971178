import React from 'react';
import {
  Carousel,
  Col,
  Row,
  Card,
} from 'antd'
import '@/pages/Main/Home/index.less';

const contentStyle = {
  height: '240px',
  color: '#fff',
  lineHeight: '240px',
  textAlign: 'center',
  background: '#4880f0',
};

const Home: React.FC = () => {
  return <div style={{padding: 16}}>
  <Row gutter={16}>
    <Col span={16}>
    <Carousel autoplay>
      <div>
        <h2 style={contentStyle}>欢迎访问深圳技术大学实习管理系统</h2>
      </div>
      <div>
        <h2 style={contentStyle}>目前处于测试阶段，如有问题，可向管理员反馈</h2>
      </div>
    </Carousel>
    </Col>
    <Col span={8}>
      <Card style={{height: 240}} title='通知公告'>

      </Card>
    </Col>
  </Row>
  <Row gutter={16}>
    <Col span={12}>
      <Card style={{height: 360, marginBottom: 16}} title='最新职位'>

      </Card>
    </Col>
    <Col span={12}>
      <Card style={{height: 360, marginBottom: 16}} title='热门讨论'>

      </Card>
    </Col>
    <Col span={12}>
      <Card style={{height: 360, marginBottom: 16}} title='通知公告'>

      </Card>
    </Col>
    <Col span={12}>
      <Card style={{height: 360, marginBottom: 16}} title='通知公告'>

      </Card>
    </Col>
  </Row>
  </div>
}

export default Home;
