import React, { useEffect, useState } from 'react';
import './index.less';
import {
  PageHeader, Button, Upload, Input, Popconfirm, Form, notification, Card, Row, Drawer
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { HeaderRouter } from '@/models/router';
import { getNewsById, submitWeeklyNews } from '@/service/weeklyNews';
import { useParams } from 'react-router-dom';
import { WeeklyNews } from '@/models/weeklyNews';
import BraftEditor from 'braft-editor';
import { upLoadFile } from '@/service/cos';

const { TextArea } = Input;

const routes: HeaderRouter[] = [
  {
    path: '/Main/WeeklyNewspaper/Home',
    breadcrumbName: '周报',
  },
  {
    path: '/Main/WeeklyNewspaper/Edit',
    breadcrumbName: '周报编辑',
  },
];

const Edit: React.FC = (props) => {
  const params: any = useParams();
  const { id } = params;

  const [news, setNews] = useState<WeeklyNews>();
  const [visible, setVisible] = useState<boolean>(false);

  const onLoad = async () => {
    const { data } = await getNewsById(id);
    data.content = BraftEditor.createEditorState(data.content);
    setNews(data);
  };

  const submit = async () => {
    try {
      await submitWeeklyNews(news);
      props.history.push(`/Main/WeeklyNewspaper/Success/${news.id}`);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const DownLoadProps = {
    name: 'file',
    multiple: false,
    fileList: [],
    customRequest: async (info: any) => {
      const { file } = info;
      const weeklyNewsUrl: string = await upLoadFile(file, 'weeklyNews');
      notification.info({message: '上传成功'})
      setNews({
        ...news,
        pdf: weeklyNewsUrl
      });
    },
    onRemove() {
      setNews({
        ...news,
        pdf: ''
      });
    },
    accept: '.rar,.zip,.doc,.docx,.pdf,.jpg,.png'
  };

  return (
    <div className="weekly-news-edit">
      <PageHeader
        className="header"
        ghost={false}
        title="周报编辑"
        breadcrumb={{ routes }}
      />
      <Card className="card">
        <div className="header">
          <div className="title">
            {news?.company}
          </div>
          <div className="time">
            <span className="week">
                 第 {news?.week} 周次
            </span>
            <span>{`${news?.start_at} ~ ${news?.end_at}`}</span>
          </div>
        </div>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
        >
          <Form.Item label="周报文件" rules={[{ required: false }]}>
            <Row style={{alignItems: 'center'}}>
              <Upload {...DownLoadProps} >
                <Button icon={<UploadOutlined />}>{
                  news?.pdf ? '重新上传' : '上传周报'
                }</Button>
              </Upload>
              {
                news?.pdf && <a style={{marginLeft: '12px'}} onClick={() => setVisible(true)}>预览</a>
              }
            </Row>
            {
              news?.pdf && <Drawer
                visible={visible}
                onClose={() => setVisible(false)}
                placement="right"
                width='40%'
                closable={false}
              >
                <iframe
                  style={{width: '100%', minHeight: '95vh', border: 'none'}}
                  src={`${news?.pdf}?ci-process=doc-preview&dstType=html`}
                />
              </Drawer>
            }
          </Form.Item>
          <Form.Item label="备注(选填)" rules={[{ required: false }]}>
            <TextArea
              placeholder="请输入您想传达给老师的备注"
              allowClear={false}
              onChange={(val) => {
                setNews({
                  ...news,
                  tip: val.currentTarget.value
                });
              }}
              value={news?.tip}
            />
          </Form.Item>
          <Form.Item label="周报在线编辑" rules={[{ required: false }]}>
            <BraftEditor
              className="editor-component"
              value={news?.content}
              onChange={(editState) => {
                console.log(editState);
                setNews({
                  ...news,
                  content: editState
                });
              }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
            <Popconfirm
              title="你确定要提交你的周报吗？"
              onConfirm={() => {
                submit();
              }}
              okText="确认"
              cancelText="取消"
            >
              <Button type="primary">确认修改</Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Edit;
