import React, { useState, useImperativeHandle } from 'react';
import { Modal, Form, Input, Button, message, InputNumber } from 'antd';
import { Service } from '@/models/service';
import { createPosition, changePosition } from '@/service/company';
import { User } from '@/models/user';
import { connect } from 'react-redux';

interface DetailProps {
  onRef: any;
  refresh: Function;
  title: string,
  user: User
}

const AddOrEdit: React.FC<DetailProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [info, setInfo] = useState<any>({});
  const [form] = Form.useForm();
  useImperativeHandle(props.onRef, () => {
    return {
      edit,
      add
    };
  });
  const close = () => {
    setIsModalVisible(false);
  };
  const edit = (_info: any) => {
    setInfo(_info);
    form.setFieldsValue({
      ..._info
    });
    setIsEdit(true);
    setIsModalVisible(true);
  };
  const add = () => {
    setIsEdit(false);
    setIsModalVisible(true);
  };
  const onFinish = async (values: any) => {
    console.log(values);
    if (isEdit) {
      const res: Service = await changePosition(
        {
          ...values,
          position_id: info.id
        }
      );
      message.info('岗位信息修改成功');
    } else {
      const res: Service = await createPosition({
        ...values
      });
      message.info('岗位创建成功');
      setInfo({})
    }

    props.refresh();
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Modal
      title={props.title}
      visible={isModalVisible}
      onOk={close}
      onCancel={close}
      maskClosable={true}
      footer={null}
      width='50vw'
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="职位"
          name="name"
          rules={[{
            required: true,
            message: '请输入职位'
          }]}
        >
          <Input placeholder='请输入职位'/>
        </Form.Item>

        <Form.Item
          label="工作地点"
          name="address"
          rules={[{
            required: true,
            message: '请输入工作地点'
          }]}
        >
          <Input placeholder='请输入工作地点'/>
        </Form.Item>

        <Form.Item
          label="薪酬"
          name="salary"
          rules={[{
            required: true,
            message: '请输入薪酬'
          }]}
        >
          <Input placeholder='请输入薪酬，如300元/天'/>
        </Form.Item>

        <Form.Item
          label="实习时长"
          name="duration"
          rules={[{
            required: true,
            message: '请输入实习时长'
          }]}
        >
          <Input placeholder='请输入实习时长，如三个月及以上'/>
        </Form.Item>

        <Form.Item
          label="专业要求"
          name="major"
          rules={[{
            required: true,
            message: '请输入专业要求'
          }]}
        >
          <Input placeholder='请输入专业要求，如计算机相关专业'/>
        </Form.Item>

        <Form.Item
          label="目标学生"
          name="aim_student"
          rules={[{
            required: true,
            message: '请输入目标学生'
          }]}
        >
          <Input placeholder='请输入目标学生，如2022届'/>
        </Form.Item>

        <Form.Item
          label="职位描述"
          name="description"
          rules={[{
            required: true,
            message: '请输入职位描述'
          }]}
        >
          <Input.TextArea rows={10} placeholder='请输入职位描述' />
        </Form.Item>

        <Form.Item wrapperCol={{
          offset: 8,
          span: 16
        }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: { User: User; }) => ({ user: state.User });
export default connect(mapStateToProps)(AddOrEdit);
