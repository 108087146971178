import axios from 'axios';
import { Service } from '@/models/service';
import { notification } from 'antd';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const request = (
  method: string,
  url: string,
  options?: any,
): Promise<Service> => new Promise((resolve, reject) => {
  axios({
    method,
    url,
    ...options,
  })
    .then((res) => {
      const { data } = res;
      if (data.code) {
        notification.error({
          message: data.msg,
        });
        reject(data.msg);
      }
      resolve(data);
    })
    .catch((err) => {
      console.error(err);
      reject(err);
    });
});

axios.defaults.baseURL = BASE_URL;
// axios的全局配置
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('XXX')
axios.interceptors.request.use(
  (config) => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    // eslint-disable-next-line no-param-reassign
    config.headers.token = sessionStorage.getItem('token') || '';
    return config;
  },
  (error) => Promise.reject(error),
);
// axios.interceptors.response.use(
//     (res) => {
//         return res
//     },
//     (error) => {
//         if (error.response.status === 401) {
//             alert('登录超时，请重新登陆')
//             window.location.href = '/Home/Login'
//         }
//         return Promise.reject(error)
//     }
// );

export { BASE_URL, request };
