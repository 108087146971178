import {Service} from '@/models/service';
import {weeklyNewsRequestParams} from '@/models/weeklyNews';
import {request} from './index';

export const getWeeklyNews = (rest: weeklyNewsRequestParams): Promise<Service> => request('GET', '/internship/week-report/student/', {
    params: {
        ...rest
    }
});

export const getNewsById = (id: number): Promise<Service> => request('GET', `/internship/week-report/${id}/`);

export const submitWeeklyNews = (news: any) => {
    request('POST', '/internship/week-report/', {
        data: {
            id: news.id,
            tip: news.tip,
            pdf: news.pdf,
            content: news.content.toHTML()
        }
    })
}
