import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Table,
  Modal,
  Input,
  notification,
  Popconfirm
} from 'antd';
import '@/pages/Main/MyDeliver/index.less';
import { getMajors, changeMajor, createMajor, removeMajor } from '@/service/superuser'
import { PlusOutlined } from '@ant-design/icons';


const MajorManager: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [createVisible, setCreateVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);
  const [data, setData] = useState<Array<any>>([]);
  const [curData, setCurData] = useState<any>();
  const [choices, setChoices] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>();
  const [changeForm] = Form.useForm()
  const [createForm] = Form.useForm()
  const onLoad = async () => {
    await getData({})
  };
  useEffect(() => {
    onLoad();
  }, []);
  const [columns, setColumns] = useState([])

  const getData = async (params: object) => {
    setLoading(true)
    const { data } = await getMajors({
      page,
      page_size,
      ...params
    })
    setData(data.list)
    setTotal(data.total)
    setChoices(data.choices)
    setColumns(data.columns.concat([
      {
        title: '操作',
        render: (_: any, record: any) => (
          <Space size="middle">
            <a onClick={() => {
              setVisible(true)
              changeForm.setFieldsValue(record)
              setCurData(record)
            }}>编辑</a>
            <Popconfirm
              title="确认要删除该行数据吗？"
              onConfirm={() => remove({major_id: record.id})}
              okText="是"
              cancelText="否"
            >
              <a href="#" style={{color: '#dc2c2c'}}>删除</a>
            </Popconfirm>
          </Space>
        )
      },
    ]))
    setLoading(false)
  }

  const change = async (values: any) => {
    for (let key in values) {
      if (values[key] === undefined){
        values[key] = null
      }
    }
    console.log(values);
    const { data } = await changeMajor({ ...values, major_id: curData.id })
    notification.success({message: data})
    setVisible(false)
    getData({})
  }

  const create = async (values: any) => {
    for (let key in values) {
      if (values[key] === undefined){
        values[key] = null
      }
    }
    console.log(values);
    const { data } = await createMajor(values)
    notification.success({message: data})
    setCreateVisible(false)
    createForm.resetFields()
    getData({})
  }

  const remove = async (values: any) => {
    const { data } = await removeMajor(values)
    notification.success({message: data})
    setCreateVisible(false)
    createForm.resetFields()
    getData({})
  }

  return (
    <div>
      <Modal
        title='修改信息'
        visible={visible}
        onCancel={() => setVisible(false)}
        mask={true}
        footer={null}
      >
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} name="change" onFinish={change} form={changeForm}>
          {
            columns?.map((item: any) => item?.edit && <Form.Item name={item.name || item.dataIndex} label={item.title}>
              {
                item?.select ? <Select
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    choices[item.dataIndex]?.map((item: any) => <Select.Option
                      value={item.value}
                    >{item.label}</Select.Option>)
                  }
                </Select> : <Input style={{marginRight: '16px'}} placeholder={`请输入${item.title}`}/>
              }
            </Form.Item>)
          }
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='新增用户'
        visible={createVisible}
        onCancel={() => setCreateVisible(false)}
        mask={true}
        footer={null}
      >
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} name="change" onFinish={create} form={createForm}>
          {
            columns?.map((item: any) => item?.edit && <Form.Item name={item.name || item.dataIndex} label={item.title}>
              {
                item?.select ? <Select
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    choices[item.dataIndex]?.map((item: any) => <Select.Option
                      value={item.value}
                    >{item.label}</Select.Option>)
                  }
                </Select> : <Input style={{marginRight: '16px'}} placeholder={`请输入${item.title}`}/>
              }
            </Form.Item>)
          }
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row
        gutter={10}
        justify="center"
        style={{
          marginTop: 20,
        }}
      >
        <Col span={23}>
          <Card>
            <Row justify='space-between' style={{margin: '16px 0'}}>
              <Col>
                <Input.Search placeholder="搜索相关内容" onSearch={(keyword: any) => getData({keyword})} allowClear />
              </Col>
              <Col>
                <Row justify='space-between'>
                  <Button type='primary' icon={<PlusOutlined />} onClick={() => setCreateVisible(true)}>
                    创建
                  </Button>
                </Row>
              </Col>
            </Row>
            <Table
              columns={columns}
              rowKey={(record: any) => record.id}
              dataSource={data}
              loading={loading}
              pagination={{
                pageSize: page_size,
                current: page,
                showSizeChanger: true,
                total: total,
                onChange: (page: number, page_size: number) => {
                  setPage(page)
                  setPageSize(page_size)
                  getData({page, page_size})
                }
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default MajorManager;
