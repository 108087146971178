import {Service} from '@/models/service';
import {request} from './index';

export const addResume = (
    name: string,
    pdf: string,
    other_file?: string,
): Promise<Service> => request('POST', '/internship/resume/', {
    data: {
        name,
        pdf,
        other_file,
    },
});

export const delResume = async (id: string): Promise<Service> => request('DELETE', `/internship/resume/${id}/`);

export const getResumeInfo = async (): Promise<Service> => request('GET', '/internship/resume/');
