import React, { useState, useImperativeHandle } from 'react'
import { Modal, Descriptions } from 'antd'

interface DetailProps {
  onRef: any;
}

export const Detail: React.FC<DetailProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [info, setInfo] = useState<any>()
  useImperativeHandle(props.onRef, () => {
    return {
      show
    }
  })
  const close = () => {
    setIsModalVisible(false);
  };
  const show = (_info: any) => {
    setInfo(_info)
    setIsModalVisible(true);
  };
  return (
    <Modal
      title="岗位详情"
      visible={isModalVisible}
      onOk={close}
      onCancel={close}
      closable={false}
      maskClosable={true}
      okText='确认'
      cancelButtonProps={{style: {display: 'none'}}}
    >
      <Descriptions column={1} labelStyle={{width: '120px'}}>
        <Descriptions.Item label='职位'>{info?.name}</Descriptions.Item>
        <Descriptions.Item label='工作地点'>{info?.address}</Descriptions.Item>
        <Descriptions.Item label='薪酬'>{info?.salary}</Descriptions.Item>
        <Descriptions.Item label='实习时长'>{info?.duration}</Descriptions.Item>
        <Descriptions.Item label='专业要求'>{info?.major}</Descriptions.Item>
        <Descriptions.Item label='目标学生'>{info?.aim_student}</Descriptions.Item>
        <Descriptions.Item label='职位描述'>
          <div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: info?.description}}></div>
        </Descriptions.Item>
        <Descriptions.Item label='发布时间'>{info?.created_at}</Descriptions.Item>
        <Descriptions.Item label='更新时间'>{info?.updated_at}</Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}
