import React, { useState, useImperativeHandle } from 'react';
import { Modal, Form, Input, Button, AutoComplete, DatePicker, message } from 'antd';
import { Service } from '@/models/service';
import { searchStudents } from '@/service/user';
import { getCompanies, getPositions } from '@/service/company';
import { createStudentInternship, changeStudentInternship } from '@/service/internship';
import moment from 'moment';
import { User } from '@/models/user';
import { connect } from 'react-redux';

interface DetailProps {
  onRef: any;
  refresh: Function;
  title: string,
  user: User
}

interface StudentSearchRes {
  name: string,
  student_id: string,
  major: string,
  college: string,
  grade: string,
  value: string,
  id: number
}

const AddOrEdit: React.FC<DetailProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [info, setInfo] = useState<any>({});
  const [nameOptions, setNameOptions] = useState<{ value: string }[]>([]);
  const [companyOptions, setCompanyOptions] = useState<{ value: string }[]>([]);
  const [positionOptions, setPositionOptions] = useState<{ value: string }[]>([]);
  const [createParams, setCreateParams] = useState<any>({ teacher_id: props.user.id });
  const [form] = Form.useForm();
  const dateFormat = 'YYYY-MM-DD';
  useImperativeHandle(props.onRef, () => {
    return {
      edit,
      add
    };
  });
  const close = () => {
    setIsModalVisible(false);
  };
  const edit = (_info: any) => {
    setInfo(_info);
    form.setFieldsValue({
      username: _info?.name || '',
      grade: _info?.grade || '',
      student_id: _info?.student_id || '',
      college: _info?.college || '',
      major: _info?.major || '',
      company: _info?.company || '',
      position: _info?.position || '',
      start_at: _info?.start_at && moment(_info.start_at, dateFormat),
      ending_at: _info?.end_at !== '--' && moment(_info.end_at, dateFormat),
    });
    setIsEdit(true);
    setIsModalVisible(true);
  };
  const add = () => {
    setIsEdit(false);
    setIsModalVisible(true);
  };
  const onFinish = async (values: any) => {
    console.log(createParams, values);
    if (isEdit) {
      const res: Service = await changeStudentInternship(info?.id, values.ending_at.format(dateFormat));
      message.info('修改成功');
    } else {
      if (!(createParams.student_id && createParams.company_id && values.position)) {
        message.error('参数错误');
        return;
      }
      const res: Service = await createStudentInternship({
        ...createParams,
        position: values.position,
        begin_at: values.start_at.format(dateFormat)
      });
      message.info('提交成功');
    }

    props.refresh();
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const searchStudentHandle = async (value: string) => {
    const res: Service = await searchStudents(value);
    setNameOptions(res.data.list);
    if (res.data.list.length === 1) {
      setCreateParams({
        ...createParams,
        student_id: res.data.list[0].id
      });
    }
  };
  const selectStudentHandle = (value: string, option: StudentSearchRes) => {
    form.setFieldsValue({
      college: option.college,
      grade: option.grade,
      student_id: option.student_id,
      major: option.major,
      username: option.name
    });
    setCreateParams({
      ...createParams,
      student_id: option.id
    });
  };
  const searchCompanyHandle = async (value: string) => {
    const res: Service = await getCompanies(value);
    setCompanyOptions(res.data.list);
    if (res.data.list.length === 1) {
      setCreateParams({
        ...createParams,
        company_id: res.data.list[0].id
      });
    }
  };
  const selectCompanyHandle = (value: string, option: { value: string }) => {
    form.setFieldsValue({
      company: option.value
    });
    setCreateParams({
      ...createParams,
      company_id: option.id
    });
  };
  const searchPositionHandle = async (value: string) => {
    const res: Service = await getPositions({keyword: value});
    setPositionOptions(res.data.list);
  };
  const selectPositionHandle = (value: string, option: { value: string }) => {
    form.setFieldsValue({
      position: option.value
    });
  };
  return (
    <Modal
      title={props.title}
      visible={isModalVisible}
      onOk={close}
      onCancel={close}
      maskClosable={true}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          username: info?.name || '',
          grade: info?.grade || '',
          student_id: info?.student_id || '',
          college: info?.college || '',
          major: info?.major || '',
          company: info?.company || '',
          position: info?.position || '',
          start_at: info?.start_at && moment(info.start_at, dateFormat),
          ending_at: info?.end_at && moment(info.end_at, dateFormat),
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="学生"
          name="username"
          rules={[{
            required: true,
            message: '请输入学生信息'
          }]}
        >
          <AutoComplete
            disabled={isEdit}
            options={nameOptions}
            onSelect={selectStudentHandle}
            onSearch={searchStudentHandle}
            placeholder="请输入学生名字或学号然后选择"
          />
        </Form.Item>

        <Form.Item
          label="学号"
          name="student_id"
          rules={[{ required: false }]}
        >
          <Input disabled/>
        </Form.Item>

        <Form.Item
          label="年级"
          name="grade"
          rules={[{ required: false }]}
        >
          <Input disabled/>
        </Form.Item>

        <Form.Item
          label="学院"
          name="college"
          rules={[{ required: false }]}
        >
          <Input disabled/>
        </Form.Item>

        <Form.Item
          label="专业"
          name="major"
          rules={[{ required: false }]}
        >
          <Input disabled/>
        </Form.Item>

        <Form.Item
          label="实习单位"
          name="company"
          rules={[{
            required: true,
            message: '请输入实习单位'
          }]}
        >
          <AutoComplete
            options={companyOptions}
            onSelect={selectCompanyHandle}
            onSearch={searchCompanyHandle}
          />
        </Form.Item>

        <Form.Item
          label="实习职位"
          name="position"
          rules={[{
            required: true,
            message: '请输入实习职位'
          }]}
        >
          <AutoComplete
            options={positionOptions}
            onSelect={selectPositionHandle}
            onSearch={searchPositionHandle}
          />
        </Form.Item>

        <Form.Item
          label="实习开始时间"
          name="start_at"
          rules={[{
            required: true,
            message: '请选择实习开始时间'
          }]}
        >
          <DatePicker disabled={isEdit} style={{ width: '100%' }} format={dateFormat}
                      placeholder="请选择开始时间"/>
        </Form.Item>

        {isEdit && <Form.Item
          label="实习结束时间"
          name="ending_at"
          rules={[{
            required: true,
            message: '请选择实习结束时间'
          }]}
        >
          <DatePicker style={{ width: '100%' }} format={dateFormat} placeholder="请选择结束时间"/>
        </Form.Item>}

        <Form.Item wrapperCol={{
          offset: 8,
          span: 16
        }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: { User: User; }) => ({ user: state.User });
export default connect(mapStateToProps)(AddOrEdit);
