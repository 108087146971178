import { Service } from '@/models/service';
import { request } from './index';

export const getStudentInternships = async (grade: number): Promise<Service> => request(
  'GET',
  `/internship/internship/?page_size=9999&grade=${grade}`,
);

export const createStudentInternship = async (data: any): Promise<Service> => request(
  'POST',
  `/internship/internship/`,
  { data }
);

export const changeStudentInternship = async (internship_id: number, ending_at: string): Promise<Service> => request(
  'PUT',
  `/internship/internship/${internship_id}/`,
  { data: { ending_at } }
);
