import React from 'react';
import '@/pages/Main/PersonalCenter/index.less';
import {PageHeader, Row, Col} from 'antd';
import {HeaderRouter} from '@/models/router';
import Info from '@/pages/Main/PersonalCenter/component/Info/index';
import Message from '@/pages/Main/PersonalCenter/component/Message/index';

const routes: HeaderRouter[] = [
    {
        path: '/Main/Home',
        breadcrumbName: '首页',
    },
    {
        path: '/Main/PersonalCenter',
        breadcrumbName: '个人中心',
    },
];

const PersonalCenter: React.FC = () => (
    <div className="personal-center">
        <PageHeader
            className="header"
            ghost={false}
            title="个人中心"
            breadcrumb={{routes}}
        />
        <Row
            gutter={10}
            justify="space-around"
            style={{
                marginTop: 20,
            }}
        >
            <Col span={6}>
                <Info/>
            </Col>
            <Col span={17}>
                <Message/>
            </Col>
        </Row>
    </div>
);

export default PersonalCenter;
