import React from 'react';
import './App.less';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '@/pages/Login';
import Register from '@/pages/Register';
import Main from '@/pages/Main';

function App() {
  return (
    <div className="window">
      <div className="app">
        <Switch>
          <Route path="/Login" component={Login} />
          <Route path="/Register" component={Register} />
          <Route path="/Main" component={Main} />
          <Redirect from="/" to="/Login" />
        </Switch>
      </div>

    </div>
  );
}

export default App;
