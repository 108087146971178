import React, { useEffect, useState } from 'react';
import '@/pages/Main/ResumeCollect/index.less';
import { Button, Card, Col, Drawer, PageHeader, Row, Select, Space, Table } from 'antd';
import { CloudDownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { getPositionsForHR, getDeliversForHR, changeDeliver } from '@/service/company';

const { Option } = Select;

const routes: any[] = [
  {
    path: '/Main/Home',
    breadcrumbName: '首页',
  },
  {
    path: '/Main/ResumeCollect',
    breadcrumbName: '简历收集',
  },
];

const grades: number[] = [0, 1, 2, 3, 4, 5].map(item => {
  return (new Date()).getFullYear() - item
})

const ResumeCollect: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);
  const [positions, setPositions] = useState<Array<any>>([]);
  const [delivers, setDelivers] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>();
  const [grade, setGrade] = useState<number>();
  const [status, setStatus] = useState<string>();
  const [curPosition, setCurPosition] = useState<any>(null);
  const [pdf, setPdf] = useState<any>(null);
  const onLoad = async () => {
    getPosition()
    handleTableChange()
  };
  useEffect(() => {
    onLoad();
  }, []);
  const getPosition = async () => {
    const data: any = await getPositionsForHR({
      page: 1,
      page_size: 9999,
      simple: 1
    });
    setPositions(data.data.list);
  }
  const handleTableChange = async (
    _grade: any = grade,
    _position_id: number = curPosition,
    _status: any = status,
    _page: number = page,
    _page_size: number = page_size
  ) => {
    setLoading(true)
    const data: any = await getDeliversForHR({
      page: _page,
      page_size: _page_size,
      grade: _grade,
      position_id: _position_id,
      status: _status
    });
    setDelivers(data.data.list);
    setTotal(data.data.total)
    setLoading(false)
  };
  const columns = [
    {
      title: '学号',
      dataIndex: 'student_id',
    },
    {
      title: '年级',
      dataIndex: 'grade',
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '专业',
      dataIndex: 'major',
    },
    {
      title: '应聘职位',
      dataIndex: 'position',
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (_: any, record: any) => (
        <Space size="middle">
          <span style={{color: record.status === '未查看' ? 'red' : '#6BA369'}}>{record.status}</span>
        </Space>
      )
    },
    {
      title: '投递时间',
      dataIndex: 'created_at',
    },
    {
      title: '操作',
      dataIndex: 'id',
      width: '10%',
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => {
            setVisible(true)
            setPdf(record.resume)
            if (record.status === '未查看') {
              changeDeliver({
                deliver_id: record.id,
                status: '已查看'
              })
            }
          }}>查看简历</a>
        </Space>
      )
    },
  ];
  return (
    <div className="student-management">
      <PageHeader
        className="header"
        ghost={false}
        title="简历收集"
        breadcrumb={{ routes }}
      />
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        placement="right"
        width='40%'
        closable={false}
      >
        <iframe
          style={{width: '100%', minHeight: '95vh', border: 'none'}}
          src={`${pdf}?ci-process=doc-preview&dstType=html`}
        />
      </Drawer>
      <Row
        gutter={10}
        justify="center"
        style={{
          marginTop: 20,
        }}
      >
        <Col span={23}>
          <Card>
            <Row justify='start' gutter={16} style={{margin: '16px 0'}}>
              <Col>
                <Row align='middle'>
                  <span style={{marginRight: 8}}>年级</span>
                  <Select value={grade} style={{ width: 120 }} allowClear placeholder='请选择年级' onChange={(val: any) => {
                    if (val === undefined) {
                      val = null
                    }
                    setGrade(val);
                    handleTableChange(val, curPosition, status)
                  }}>
                    {grades.map(grade => (
                      <Option key={grade} value={grade}>{grade + 4}届</Option>
                    ))}
                  </Select>
                </Row>
              </Col>
              <Col>
                <Row align='middle'>
                  <span style={{marginRight: 8}}>应聘岗位</span>
                  <Select placeholder='请选择应聘岗位' allowClear value={curPosition} style={{ width: 200 }} onChange={(val: any) => {
                    if (val === undefined) {
                      val = null
                    }
                    setCurPosition(val);
                    handleTableChange(grade, val, status)
                  }}>
                    {positions.map(position => (
                      <Option key={position.id} value={position.id}>{position.name}</Option>
                    ))}
                  </Select>
                </Row>
              </Col>
              <Col>
                <Row align='middle'>
                  <span style={{marginRight: 8}}>状态</span>
                  <Select placeholder='请选择状态' allowClear value={status} style={{ width: 200 }} onChange={(val: any) => {
                    if (val === undefined) {
                      val = null
                    }
                    setStatus(val);
                    handleTableChange(grade, curPosition, val)
                  }}>
                    {['未查看', '已查看'].map(item => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </Select>
                </Row>
              </Col>
            </Row>
            <Table
              columns={columns}
              rowKey={(record: any) => record.id}
              dataSource={delivers}
              loading={loading}
              pagination={{
                pageSize: page_size,
                current: page,
                showSizeChanger: true,
                total: total,
                onChange: (_page: number, _page_size: number) => {
                  setPage(_page)
                  setPageSize(_page_size)
                  handleTableChange(_page, _page_size)
                }
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ResumeCollect;
