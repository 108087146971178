import { Service } from '@/models/service';
import { request } from './index';

export const getStudentWeekReports = async (params: object): Promise<Service> => request(
  'GET',
  `/internship/week-report/`,
  { params: {...params, page_size: 9999} }
);

export const commentWeekReport = async (data: object): Promise<Service> => request(
  'POST',
  `/internship/week-report/comment/`,
  { data }
);
