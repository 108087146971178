import { SET_USER } from '../constant';
import { User } from '@/models/user'

export default function UserReducer(prevState: User = {}, action: { type: any; data: User; }) {
  const { type, data } = action;
  switch (type) {
    case SET_USER:
      return data;
    default:
      return prevState;
  }
}
