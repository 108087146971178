import React, { useEffect, useState } from 'react';
import '@/pages/Main/PublishJob/index.less';
import {
  Button,
  Card,
  Col,
  message,
  PageHeader,
  Row,
  Space,
  Table,
  Popconfirm,
  Select
} from 'antd';
import { getPositionsForHR, changePosition } from '@/service/company';
import { HeaderRouter } from '@/models/router';
import { PlusOutlined } from '@ant-design/icons';
import { Detail } from '@/pages/Main/PublishJob/component/Detail'
import AddOrEdit from '@/pages/Main/PublishJob/component/AddOrEdit'

const { Option } = Select

const routes: HeaderRouter[] = [
  {
    path: '/Main/Home',
    breadcrumbName: '首页',
  },
  {
    path: '/Main/StudentManagement',
    breadcrumbName: '职位管理',
  },
];

const PublishJob: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);
  const [positions, setPositions] = useState<Array<any>>([]);
  const [status, setStatus] = useState<number>();
  const [total, setTotal] = useState<number>();
  const detailRef = React.createRef<any>();
  const addRef = React.createRef<any>();
  const editRef = React.createRef<any>();

  const onLoad = async () => {
    await handleTableChange(page, page_size)
  };
  useEffect(() => {
    onLoad();
  }, []);

  const handleTableChange = async (_page: number = page, _page_size: number = page_size, _status: number = status) => {
    setLoading(true)
    const data: any = await getPositionsForHR({
      page: _page,
      page_size: _page_size,
      stop: _status
    });
    setPositions(data.data.list);
    setTotal(data.data.total)
    setLoading(false)
  };
  const columns = [
    {
      title: '职位',
      dataIndex: 'name',
    },
    {
      title: '创建人',
      dataIndex: 'creator',
    },
    {
      title: '状态',
      dataIndex: 'stop',
      render: (val: boolean) => <span>{ val ? '已停止收集' : '正在收集' }</span>
    },
    {
      title: '总投递',
      dataIndex: 'total',
    },
    {
      title: '未查看',
      dataIndex: 'no_read',
    },
    {
      title: '目标学生',
      dataIndex: 'aim_student',
    },
    {
      title: '实习时长',
      dataIndex: 'duration'
    },
    {
      title: '操作',
      dataIndex: 'id',
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => editRef.current.edit(record)}>编辑</a>
          <a onClick={() => detailRef.current.show(record)}>查看</a>
          <Popconfirm title="确定删除岗位吗？误删可找管理员找回" okText="是" cancelText="否" onConfirm={() => {
            changePosition({
              position_id: record.id,
              delete: true
            }).then(() => {
              handleTableChange()
              message.success("删除成功")
            })
          }}>
            <a style={{ color: 'red' }}>删除</a>
          </Popconfirm>
          {
            record.stop || <a style={{ color: '#E6A23C' }} onClick={() => {
              changePosition({
                position_id: record.id,
                stop: true
              }).then(() => {
                handleTableChange()
                message.success("停止收集简历成功")
              })
            }}>停止</a>
          }
          {
            record.stop && <a style={{ color: '#8be63c' }} onClick={() => {
              changePosition({
                position_id: record.id,
                stop: false
              }).then(() => {
                handleTableChange()
                message.success("继续收集简历成功")
              })
            }}>继续</a>
          }

        </Space>
      )
    },
  ];
  return <div className='publish-job'>
    <Detail onRef={detailRef}/>
    <AddOrEdit onRef={addRef} title='添加职位' refresh={() => handleTableChange(page, page_size)}/>
    <AddOrEdit onRef={editRef} title='编辑职位' refresh={() => handleTableChange(page, page_size)}/>
    <PageHeader
      className="header"
      ghost={false}
      title="职位管理"
      breadcrumb={{ routes }}
    />
    <Row
      gutter={10}
      justify="center"
      style={{
        marginTop: 20,
      }}
    >
      <Col span={23}>
        <Card>
          <Row justify='space-between' style={{margin: '16px 0'}}>
            <Col>
              <Row align='middle'>
                <span style={{marginRight: 8}}>状态</span>
                <Select value={status} style={{ width: 180 }} placeholder='请选择岗位状态' allowClear onChange={(val: any) => {
                    if (val === undefined) {
                      val = null
                    }
                    setStatus(val)
                    handleTableChange(page, page_size, val)
                  }}>
                  <Option value="0">正在收集</Option>
                  <Option value="1">停止收集</Option>
                </Select>
              </Row>
            </Col>
            <Col>
              <Row justify='space-between'>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => addRef.current.add()}>
                  添加职位
                </Button>
              </Row>
            </Col>
          </Row>
          <Table
            columns={columns}
            rowKey={(record: any) => record.id}
            dataSource={positions}
            loading={loading}
            pagination={{
              pageSize: page_size,
              current: page,
              showSizeChanger: true,
              total: total,
              onChange: (_page: number, _page_size: number) => {
                setPage(_page)
                setPageSize(_page_size)
                handleTableChange(_page, _page_size)
              }
            }}
          />
        </Card>
      </Col>
    </Row>
  </div>;
};

export default PublishJob;
