import { request } from './index';
import { Service } from '@/models/service';

export const getCompanies = async (keyword: string = ''): Promise<Service> => request(
  'GET',
  `/company/?keyword=${keyword}`,
);

export const getPositions = async (params: object): Promise<Service> => request(
  'GET',
  `/company/position/`,
  { params }
);

export const createPosition = async (data: object): Promise<Service> => request(
  'POST',
  '/company/position/',
  { data }
)

export const changePosition = async (data: object): Promise<Service> => request(
  'POST',
  '/company/position/change/',
  { data }
)

export const getPositionsForHR = async (params: object): Promise<Service> => request(
  'GET',
  '/company/position/hr/',
  { params }
)

export const getDeliversForStudent = async (params: object): Promise<Service> => request(
  'GET',
  '/company/deliver/student/',
  { params }
)

export const getPositionsFilterParams = async (): Promise<Service> => request(
  'GET',
  '/company/position/filter-params/',
)

export const changeDeliver = async (data: object): Promise<Service> => request(
  'POST',
  '/company/deliver/change/',
  { data }
)

export const getDeliversForHR = async (params: object): Promise<Service> => request(
  'GET',
  '/company/deliver/hr/',
  { params }
)

export const deliverResume = async (data: object): Promise<Service> => request(
  'POST',
  '/company/deliver/',
  { data }
)

export const getCompanyForHR = async (params: object): Promise<Service> => request(
  'GET',
  '/company/hr/',
  { params }
)

export const changeCompanyInfo = async (data: object): Promise<Service> => request(
  'POST',
  '/company/change/',
  { data }
)
