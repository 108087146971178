import React, { useState } from 'react';
import {
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import ProForm, {
  ProFormCaptcha,
  ProFormCheckbox,
  ProFormText,
} from '@ant-design/pro-form';
import { Alert, message, Tabs } from 'antd';
import { login } from '@/service/user';

import './index.less';
import logo from '@/assets/img/logo.png';

const LoginMessage = ({ content = '' }) => (
  <Alert
    style={{
      marginBottom: 24,
    }}
    message={content}
    type="error"
    showIcon
  />
);

const Login: React.FC = (props: any) => {
  const { userLogin = {}, submitting } = props;
  const { status, type: loginType } = userLogin;
  const [type, setType] = useState('account');

  const handleSubmit = async (values: any) => {
    // eslint-disable-next-line no-param-reassign
    delete values.autoLogin;
    const { code, msg, data } = await login(values);
    if (code) {
      message.error(msg);
      return;
    }
    const { token } = data;
    sessionStorage.setItem('token', token);
    props.history.push('/Main/Home');
  };

  return (
    <div className="login_container">
      <div className="login_box">
        <div className="right">
          <div className="content">
            <div className="top">
              <div className="header">
                <img alt="logo" className="logo" src={logo} />
                <span className="title">深圳技术大学企业实习管理系统</span>
              </div>
              <div className="desc">
                <span>深圳技术大学企业实习管理系统欢迎您！</span>
              </div>
            </div>
            <ProForm
              initialValues={{
                autoLogin: true,
                password: '',
                email: '',
              }}
              submitter={{
                render: (_, dom) => dom.pop(),
                submitButtonProps: {
                  loading: submitting,
                  size: 'large',
                  style: {
                    width: '100%',
                  },
                },
                searchConfig: {
                  submitText: '登录',
                },
              }}
              onFinish={(values) => {
                handleSubmit(values);
                return Promise.resolve();
              }}
            >
              <Tabs activeKey={type} onChange={setType}>
                <Tabs.TabPane key="account" tab="账户密码登录" />
                {/*<Tabs.TabPane key="mobile" tab="手机号登录" />*/}
              </Tabs>

              {status === 'error' && loginType === 'account' && !submitting && (
                <LoginMessage content="账户或密码错误" />
              )}
              {type === 'account' && (
                <>
                  <ProFormText
                    name="email"
                    fieldProps={{
                      size: 'large',
                      prefix: <UserOutlined className="prefixIcon" />,
                    }}
                    placeholder="请输入学号/邮箱"
                    rules={[
                      {
                        required: true,
                        message: '请输入用户名',
                      },
                    ]}
                  />
                  <ProFormText.Password
                    name="password"
                    allowClear
                    fieldProps={{
                      size: 'large',
                      prefix: <LockOutlined className="prefixIcon" />,
                    }}
                    placeholder="密码"
                    rules={[
                      {
                        required: true,
                        message: '请输入密码！',
                      },
                    ]}
                  />
                </>
              )}

              {status === 'error' && loginType === 'mobile' && !submitting && (
                <LoginMessage content="验证码错误" />
              )}
              {type === 'mobile' && (
                <>
                  <ProFormText
                    fieldProps={{
                      size: 'large',
                      prefix: <MobileOutlined className="prefixIcon" />,
                    }}
                    name="mobile"
                    placeholder="手机号"
                    rules={[
                      {
                        required: true,
                        message: '请输入手机号！',
                      },
                      {
                        pattern: /^1\d{10}$/,
                        message: '手机号格式错误！',
                      },
                    ]}
                  />
                  <ProFormCaptcha
                    fieldProps={{
                      size: 'large',
                      prefix: <MailOutlined className="prefixIcon" />,
                    }}
                    captchaProps={{
                      size: 'large',
                    }}
                    placeholder="请输入验证码"
                    captchaTextRender={(timing, count) => {
                      if (timing) {
                        return `${count} 获取验证码`;
                      }

                      return '获取验证码';
                    }}
                    name="captcha"
                    rules={[
                      {
                        required: true,
                        message: '请输入验证码！',
                      },
                    ]}
                    onGetCaptcha={async () => {
                      // const result = await getFakeCaptcha(mobile);

                      // if (result === false) {
                      // return;
                      // }

                      message.success('获取验证码成功！验证码为：1234');
                    }}
                  />
                </>
              )}
              <div
                style={{
                  marginBottom: 24,
                }}
              >
                <ProFormCheckbox noStyle name="autoLogin">
                  <span>自动登录</span>
                </ProFormCheckbox>
                <a
                  style={{
                    float: 'right',
                  }}
                  href="/"
                >
                  <span>忘记密码</span>
                </a>
              </div>
            </ProForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
