import React, {useEffect, useState} from 'react';
import '@/pages/Main/JobSquare/index.less';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Empty,
  Image,
  Input,
  Modal,
  PageHeader,
  Pagination,
  Row,
  Tag,
  Select,
  Space,
  notification
} from 'antd';
import { connect } from 'react-redux';
import { getPositionsFilterParams, getPositions, deliverResume } from '@/service/company';
import {  } from '@/service/user';
import { UserRole } from '@/utils/constant'
import { getResumeInfo } from '@/service/resume';

const routes: any[] = [
  {
    path: '/Main/Home',
    breadcrumbName: '首页',
  },
  {
    path: '/Main/JobSquare',
    breadcrumbName: '职位广场',
  },
];

const grades: string[] = [-1, 0, 1, 2, 3].map(item => {
  if (item === -1) {
    return '不限';
  }
  return `${(new Date()).getFullYear() + item}届`;
});

const JobSquare: React.FC<{ user: object }> = (prop) => {
  const { user } = prop
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [info, setInfo] = useState<any>()
  const [positions, setPositions] = useState<Array<any> | null>(null);
  const [addressSet, setAddressSet] = useState<Array<string>>([
    '不限',
    '深圳',
    '北京',
    '上海',
    '广州',
  ]);
  const [years, setYears] = useState<Array<string>>(grades);
  const [companies, setCompanies] = useState<Array<any>>([
    '不限', '腾讯', '字节跳动'
  ]);
  const sorts = [
    {
      orderBy: 'latest',
      name: '最新'
    },
    {
      orderBy: 'hotest',
      name: '最热'
    },
  ];
  const [curAddress, setCurAddress] = useState<string>('不限');
  const [curYear, setCurYear] = useState<string>('不限');
  const [curSort, setCurSort] = useState<string>('latest');
  const [curCompany, setCurCompany] = useState<string>('不限');
  const [keyword, setKeyword] = useState<string>('');
  const [total, setTotal] = useState<number>();
  const [pageSize, setPagesize] = useState<number>(12);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resumes, setResumes] = useState<Array<any>>([])
  const [curResume, setCurResume] = useState<string>()

  const onLoad = async () => {
    await getFilterParams()
    await getPositionList({})
    await getResumes()
  };
  useEffect(() => {
    onLoad();
  }, []);

  const getFilterParams = async () => {
    const { data } = await getPositionsFilterParams()
    setAddressSet(data.address)
    setCompanies(data.company)
  }

  const getResumes = async () => {
    const {data} = await getResumeInfo()
    setResumes(data.list)
  }

  const getPositionList = async (params: object) => {
    const { data } = await getPositions({
      keyword,
      address: curAddress,
      grade: curYear,
      company_name: curCompany,
      page: currentPage,
      size: pageSize,
      sort: curSort,
      ...params
    })
    if(data.list.length === 0){
      setPositions(null)
    }else{
      setPositions(data.list)
    }
    setTotal(data.total)
  }

  const deliver = async (params: object) => {
    const { data } = await deliverResume(params)
    notification.success({
      message: data.msg
    })
  };
  const close = () => {
    setIsModalVisible(false);
  };
  return (
    <div className="job-square">
      <Modal
        title="岗位详情"
        visible={isModalVisible}
        onCancel={close}
        closable={false}
        maskClosable={true}
        okText='投递简历'
        cancelText='下次一定'
        footer={user.role !== UserRole.Student ? null : [
          <Select placeholder='请选择简历' style={{ width: 240, marginRight: 8, textAlign: 'left' }} onChange={value => {
            setCurResume(value)
          }}>
            {
              resumes.map(item => <Select.Option key={item.id} value={item.pdf}>
                {item.name}
              </Select.Option>)
            }
          </Select>,
          <Button key="back" onClick={close}>
            下次一定
          </Button>,
          <Button key="submit" type="primary" onClick={() => {
            if (!curResume) {
              notification.error({message: '请选择要投递的简历'})
              return
            }
            deliver({
              position_id: info.id,
              resume: curResume
            })
          }}>
            投递简历
          </Button>
        ]}
      >
        <Descriptions column={1} labelStyle={{width: '120px'}}>
          <Descriptions.Item label='职位'>{info?.name}</Descriptions.Item>
          <Descriptions.Item label='工作地点'>{info?.address}</Descriptions.Item>
          <Descriptions.Item label='薪酬'>{info?.salary}个月及以上</Descriptions.Item>
          <Descriptions.Item label='实习时长'>{info?.duration}</Descriptions.Item>
          <Descriptions.Item label='专业要求'>{info?.major}</Descriptions.Item>
          <Descriptions.Item label='目标学生'>{info?.aim_student}</Descriptions.Item>
          <Descriptions.Item label='职位描述'>
            <div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: info?.description}}></div>
          </Descriptions.Item>
          <Descriptions.Item label='发布时间'>{info?.created_at}</Descriptions.Item>
          <Descriptions.Item label='更新时间'>{info?.updated_at}</Descriptions.Item>
        </Descriptions>
      </Modal>
      <Row gutter={16} style={{ margin: 16 }}>
        <Col xl={{span: 18}} lg={{span: 24}}>
          <Card>
            <Input.Search
              placeholder="搜索岗位或者公司"
              enterButton
              size="large"
              value={keyword}
              onChange={async (e) => {
                setKeyword(e.target.value)
                await getPositionList({
                  keyword: e.target.value
                })
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{
        margin: '0 16px',
        fontSize: 16
      }}>
        <Col xl={{span: 18}} lg={{span: 24}}>
          <Card style={{ fontSize: 16 }} className="filters">
            {/*<Row className="filter">*/}
            {/*  <Col span={3}>*/}
            {/*    工作地点：*/}
            {/*  </Col>*/}
            {/*  <Col span={20}>*/}
            {/*    {addressSet.map(addr => (*/}
            {/*      <Tag.CheckableTag*/}
            {/*        key={addr}*/}
            {/*        checked={addr === curAddress}*/}
            {/*        onClick={async () => {*/}
            {/*          setCurAddress(addr)*/}
            {/*          await getPositionList({*/}
            {/*            grade: curYear,*/}
            {/*          })*/}
            {/*        }}*/}
            {/*        style={{ fontSize: 16 }}*/}
            {/*      >*/}
            {/*        {addr}*/}
            {/*      </Tag.CheckableTag>*/}
            {/*    ))}*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Row className="filter">
              <Col span={3}>
                招聘对象：
              </Col>
              <Col span={20}>
                {years.map(year => (
                  <Tag.CheckableTag
                    key={year}
                    checked={year === curYear}
                    onClick={async () => {
                      setCurYear(year)
                      await getPositionList({
                        grade: year,
                      })
                    }}
                    style={{ fontSize: 16 }}
                  >
                    {year}
                  </Tag.CheckableTag>
                ))}
              </Col>
            </Row>
            <Row className="filter">
              <Col span={3}>
                招聘企业：
              </Col>
              <Col span={20}>
                {companies.map(item => (
                  <Tag.CheckableTag
                    key={item}
                    checked={item === curCompany}
                    onClick={async () => {
                      setCurCompany(item)
                      await getPositionList({
                        company_name: item
                      })
                    }}
                    style={{ fontSize: 16 }}
                  >
                    {item}
                  </Tag.CheckableTag>
                ))}
              </Col>
            </Row>
            <Row className="filter">
              <Col span={3}>
                排序方式：
              </Col>
              <Col span={20}>
                {sorts.map(item => (
                  <Tag.CheckableTag
                    key={item.orderBy}
                    checked={item.orderBy === curSort}
                    onClick={async () => {
                      setCurSort(item.orderBy)
                      await getPositionList({
                        sort: item.orderBy
                      })
                    }}
                    style={{ fontSize: 16 }}
                  >
                    {item.name}
                  </Tag.CheckableTag>
                ))}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{
        margin: '0 16px',
        fontSize: 16,
        marginTop: 16
      }}>
        {
          positions ? positions.map(position => <Col lg={{span: 12}} xl={{span: 8}}>
            <Card style={{marginBottom: 16}}>
              <Row gutter={16} align='middle'>
                <Col span={4}>
                  <Image src={position.logo} preview={false}/>
                </Col>
                <Col span={14}>
                  <Row>{position.name}</Row>
                  <Row>
                    <Tag>{position.duration}</Tag>
                    <Tag>{position.salary}</Tag>
                    <Tag>{position.aim_student}</Tag>
                  </Row>
                </Col>
                <Col span={4}>
                  <Button size='small' onClick={() => {
                    setIsModalVisible(true)
                    setInfo(position)
                  }}>查看详情</Button>
                </Col>
              </Row>
            </Card>
          </Col>) : <Col span={24}>
            <Card>
              <Empty description="找不到相关岗位"/>
            </Card>
          </Col>
        }
        <Col span={24}>
          <Row justify='end'>
            <Pagination pageSize={pageSize} total={total} current={currentPage} onChange={async (page, size) => {
              setPagesize(size || pageSize)
              setCurrentPage(page)
              await getPositionList({
                page,
                size
              })
            }}/>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: { User: object; }) => ({ user: state.User });

export default connect(mapStateToProps)(JobSquare);
