import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Table,
  Modal,
  Input,
  notification,
  Popconfirm,
  Upload,
  Tooltip
} from 'antd';
import '@/pages/Main/MyDeliver/index.less';
import { getUsers, changeUser, createUser, removeUser, bulkCreateUser } from '@/service/superuser'
import { upLoadFile } from '@/service/cos'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';


const UserManager: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [createVisible, setCreateVisible] = useState<boolean>(false);
  const [bulkCreateVisible, setBulkCreateVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);
  const [data, setData] = useState<Array<any>>([]);
  const [curData, setCurData] = useState<any>();
  const [excelUrl, setExcelUrl] = useState<any>();
  const [choices, setChoices] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>();
  const [changeForm] = Form.useForm()
  const [createForm] = Form.useForm()

  const uploadProps: any = {
    name: 'file',
    multiple: false,
    fileList: excelUrl ? [
      {
        uid: '1',
        name: '模板文件.xlsx',
        status: 'done',
        url: 'https://internship-static-1259072275.cos.ap-guangzhou.myqcloud.com/excel/模板文件.xlsx',
      },
      {
        uid: '1',
        name: '你上传的文件.xlsx',
        status: 'done',
        url: excelUrl,
      }
    ] : [
      {
        uid: '1',
        name: '模板文件.xlsx',
        status: 'done',
        url: 'https://internship-static-1259072275.cos.ap-guangzhou.myqcloud.com/excel/模板文件.xlsx',
      },
    ],
    customRequest: async (info: any) => {
      const { file } = info;
      const url: string = await upLoadFile(file, 'excel');
      notification.info({message: '上传成功'})
      setExcelUrl(url)
    },
    onRemove() {
    },
    accept: '.xlsx,.xls',
    showUploadList: {
      showDownloadIcon: true,
      showRemoveIcon: false,
    },
  };

  const onLoad = async () => {
    await getData({})
  };
  useEffect(() => {
    onLoad();
  }, []);
  const [columns, setColumns] = useState([])

  const getData = async (params: object) => {
    setLoading(true)
    const { data } = await getUsers({
      page,
      page_size,
      ...params
    })
    setData(data.list)
    setTotal(data.total)
    setChoices(data.choices)
    setColumns(data.columns.concat([
      {
        title: '操作',
        render: (_: any, record: any) => (
          <Space size="middle">
            <a onClick={() => {
              setVisible(true)
              changeForm.setFieldsValue(record)
              setCurData(record)
            }}>编辑</a>
            <Popconfirm
              title="确认要删除该行数据吗？"
              onConfirm={() => remove({user_id: record.id})}
              okText="是"
              cancelText="否"
            >
              <a href="#" style={{color: '#dc2c2c'}}>删除</a>
            </Popconfirm>
          </Space>
        )
      },
    ]))
    setLoading(false)
  }

  const change = async (values: any) => {
    for (let key in values) {
      if (values[key] === undefined){
        values[key] = null
      }
    }
    console.log(values);
    const { data } = await changeUser({ ...values, user_id: curData.id })
    notification.success({message: data})
    setVisible(false)
    getData({})
  }

  const create = async (values: any) => {
    for (let key in values) {
      if (values[key] === undefined){
        values[key] = null
      }
    }
    console.log(values);
    const { data } = await createUser(values)
    notification.success({message: data})
    setCreateVisible(false)
    createForm.resetFields()
    getData({})
  }

  const remove = async (values: any) => {
    const { data } = await removeUser(values)
    notification.success({message: data})
    getData({})
  }

  const bulkCreate = async () => {
    if(!excelUrl) {
      notification.error({message: '请先上传表格'})
    }
    setModalLoading(true)
    try {
      const { data } = await bulkCreateUser({excel: excelUrl})
      notification.success({message: data})
      setBulkCreateVisible(false)
      getData({})
      setExcelUrl(null)
    } catch (e) {
      console.log(e);
    } finally {
      setModalLoading(false)
    }
  }

  return (
    <div>
      <Modal
        title='修改信息'
        visible={visible}
        onCancel={() => setVisible(false)}
        mask={true}
        footer={null}
      >
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} name="change" onFinish={change} form={changeForm}>
          {
            columns?.map((item: any) => item?.edit && <Form.Item name={item.name || item.dataIndex} label={item.title}>
              {
                item?.select ? <Select
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    choices[item.dataIndex]?.map((item: any) => <Select.Option
                      value={item.value}
                    >{item.label}</Select.Option>)
                  }
                </Select> : <Input style={{marginRight: '16px'}} placeholder={`请输入${item.title}`}/>
              }
            </Form.Item>)
          }
          <Form.Item name='password' label="重置密码">
            <Input style={{marginRight: '16px'}} type='password' placeholder='请输入密码'/>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='新增用户'
        visible={createVisible}
        onCancel={() => setCreateVisible(false)}
        mask={true}
        footer={null}
      >
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} name="change" onFinish={create} form={createForm}>
          {
            columns?.map((item: any) => item?.edit && <Form.Item name={item.name || item.dataIndex} label={item.title}>
              {
                item?.select ? <Select
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    choices[item.dataIndex]?.map((item: any) => <Select.Option
                      value={item.value}
                    >{item.label}</Select.Option>)
                  }
                </Select> : <Input style={{marginRight: '16px'}} placeholder={`请输入${item.title}`}/>
              }
            </Form.Item>)
          }
          <Form.Item name='password' label="密码" rules={[{ required: true }]}>
            <Input style={{marginRight: '16px'}} type='password' placeholder='请输入密码'/>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='批量创建用户'
        visible={bulkCreateVisible}
        onCancel={() => setBulkCreateVisible(false)}
        mask={true}
        footer={null}
      >
        <Row>
          <Upload {...uploadProps}>
            <Tooltip placement="topLeft" title="下载模板excel文件填写再上传">
              <Button icon={<UploadOutlined />}>上传excel文件</Button>
            </Tooltip>
          </Upload>
        </Row>
        <Row style={{marginTop: 12}}>
          <Space>
            <Button type="primary" onClick={bulkCreate} loading={modalLoading}>
              提交
            </Button>
          </Space>
        </Row>
      </Modal>
      <Row
        gutter={10}
        justify="center"
        style={{
          marginTop: 20,
        }}
      >
        <Col span={23}>
          <Card>
            <Row justify='space-between' style={{margin: '16px 0'}}>
              <Col>
                <Input.Search placeholder="搜索相关内容" onSearch={(keyword: any) => getData({keyword})} allowClear />
              </Col>
              <Col>
                <Row justify='space-between'>
                  <Space>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setBulkCreateVisible(true)}>
                      批量创建
                    </Button>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setCreateVisible(true)}>
                      创建
                    </Button>
                  </Space>
                </Row>
              </Col>
            </Row>
            <Table
              columns={columns}
              rowKey={(record: any) => record.id}
              dataSource={data}
              loading={loading}
              pagination={{
                pageSize: page_size,
                current: page,
                showSizeChanger: true,
                total: total,
                onChange: (page: number, page_size: number) => {
                  setPage(page)
                  setPageSize(page_size)
                  getData({page, page_size})
                }
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default UserManager;
