import React, { useState, useImperativeHandle } from 'react'
import { Modal, Descriptions } from 'antd'
import { InternshipInfo } from '@/models/internship';

interface DetailProps {
  onRef: any;
}

export const Detail: React.FC<DetailProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [info, setInfo] = useState<InternshipInfo>()
  useImperativeHandle(props.onRef, () => {
    return {
      show
    }
  })
  const close = () => {
    setIsModalVisible(false);
  };
  const show = (_info: InternshipInfo) => {
    setInfo(_info)
    setIsModalVisible(true);
  };
  return (
    <Modal
      title="实习详情"
      visible={isModalVisible}
      onOk={close}
      onCancel={close}
      closable={false}
      maskClosable={true}
      okText='确认'
      cancelButtonProps={{style: {display: 'none'}}}
    >
      <Descriptions column={1} labelStyle={{width: '120px'}}>
        <Descriptions.Item label='姓名'>{info?.name}</Descriptions.Item>
        <Descriptions.Item label='学号'>{info?.student_id}</Descriptions.Item>
        <Descriptions.Item label='年级'>{info?.grade}</Descriptions.Item>
        <Descriptions.Item label='学院'>{info?.college}</Descriptions.Item>
        <Descriptions.Item label='专业'>{info?.major}</Descriptions.Item>
        <Descriptions.Item label='实习单位'>{info?.company}</Descriptions.Item>
        <Descriptions.Item label='实习职位'>{info?.position}</Descriptions.Item>
        <Descriptions.Item label='实习开始时间'>{info?.start_at}</Descriptions.Item>
        <Descriptions.Item label='实习结束时间'>{info?.end_at}</Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}
