import React, { useEffect, useState } from 'react';
import {
  PageHeader,
  Row,
  Col,
  Card,
  Table,
  Drawer,
  List,
  Pagination,
  Typography
} from 'antd'
import { getAnnouncesForUser } from '@/service/school'
import '@/pages/Main/Announcement/index.less';
import 'braft-editor/dist/output.css'

const routes: any[] = [
  {
    path: '/Main/Home',
    breadcrumbName: '首页',
  },
  {
    path: '/Main/Announcement',
    breadcrumbName: '公告',
  },
];

const Announcement: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);
  const [announces, setAnnounces] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>();
  const [announce, setAnnounce] = useState<any>(null);
  const onLoad = async () => {
    getAnnounces({})
  };
  useEffect(() => {
    onLoad();
  }, []);

  const getAnnounces = async (params: object) => {
    const {data} = await getAnnouncesForUser({
      page,
      page_size,
      ...params
    })
    setTotal(data.total)
    setAnnounces(data.list)
  } 
  return (
    <div>
      <PageHeader
        className="header"
        ghost={false}
        title="公告"
        breadcrumb={{ routes }}
        style={{marginTop: 2}}
      />
      <Drawer
          visible={visible}
          onClose={() => setVisible(false)}
          placement="right"
          width='50%'
          closable={false}
        >
          <Typography.Title style={{textAlign: 'center'}} level={3}>
            {announce?.title}
          </Typography.Title>
          <Typography.Title level={5} style={{textAlign: 'center', color: '#777'}}>
            {announce?.creator} 发布于 {announce?.created_at}
          </Typography.Title>
          {
            announce?.type === 'text' ? <div 
            className="braft-output-content"
            dangerouslySetInnerHTML={{__html: announce?.content}}
            />
             : <iframe
            style={{width: '100%', minHeight: '95vh', border: 'none'}}
            src={`${announce?.doc}?ci-process=doc-preview&dstType=html`}/>
          }
        </Drawer>
        <Row
          gutter={10}
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Col span={23}>
          <List
            size="small"
            style={{background: '#fff'}}
            dataSource={announces}
            renderItem={item => <List.Item>
              <Row justify='space-between' style={{width: '100%'}}>
                <Col>
                  <a onClick={() => {
                    setVisible(true)
                    setAnnounce(item)
                    }}>{item.title}</a>
                </Col>
                <Col>
                  {item.created_at}
                </Col>
              </Row>
            </List.Item>}
            pagination={false}
          />
          </Col>
          <Col
            span={23}
            style={{textAlign: 'right', marginTop: 16}}
          >
            <Pagination
              pageSize={page_size}
              current={page}
              total={total}
              onChange={(page, page_size) => {
                setPage(page)
                page_size && setPageSize(page_size)
                getAnnounces({
                  page_size,
                  page
                })
              }}
            />
          </Col>
        </Row>
    </div>
  )
}

export default Announcement;
