import { ADD_PERSON } from '../constant';

const initState = [{ id: '1', name: '1', age: 18 }];

export default function personReducer(preState = initState, action) {
  const { type, data: personObj } = action;
  const newState = preState.slice();
  switch (type) {
    case ADD_PERSON:
      newState.unshift(personObj);
      return newState;

    default:
      return preState;
  }
}
