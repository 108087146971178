import React, { useEffect, useState } from 'react';
import { Col, PageHeader, Row, Table, Card, Select, Button, Space } from 'antd';
import { PlusOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Detail } from '@/pages/Main/StudentManagement/component/Detail'
import AddOrEdit from '@/pages/Main/StudentManagement/component/AddOrEdit'
import '@/pages/Main/StudentManagement/index.less';
import { HeaderRouter } from '@/models/router';
import { InternshipInfo } from '@/models/internship';
import { Service } from '@/models/service';
import { getStudentInternships } from '@/service/internship';


const { Option } = Select;

const routes: HeaderRouter[] = [
  {
    path: '/Main/Home',
    breadcrumbName: '首页',
  },
  {
    path: '/Main/StudentManagement',
    breadcrumbName: '学生管理',
  },
];

const grades: number[] = [0, 1, 2, 3, 4, 5].map(item => {
  return (new Date()).getFullYear() - item
})

const StudentManagement: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [grade, setGrade] = useState<number>(grades[3]);
  const [internship, setInternship] = useState<InternshipInfo[]>([]);
  const detailRef = React.createRef<any>();
  const addRef = React.createRef<any>();
  const editRef = React.createRef<any>();

  const onLoad = async () => {
    await handleTableChange(grade)
  };
  useEffect(() => {
    onLoad();
  }, []);

  const handleTableChange = async (grade: number) => {
    setLoading(true)
    const internshipData: Service = await getStudentInternships(grade);
    setInternship(internshipData.data.list);
    setLoading(false)
  };
  const columns = [
    {
      title: '学号',
      dataIndex: 'student_id',
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '实习单位',
      dataIndex: 'company',
      width: '25%'
    },
    {
      title: '职位',
      dataIndex: 'position',
    },
    {
      title: '实习开始时间',
      dataIndex: 'start_at',
      width: '15%'
    },
    {
      title: '实习结束时间',
      dataIndex: 'end_at',
      width: '15%'
    },
    {
      title: '操作',
      dataIndex: 'id',
      width: '10%',
      render: (_: any, record: InternshipInfo) => (
        <Space size="middle">
          <a onClick={() => editRef.current.edit(record)}>编辑</a>
          <a onClick={() => detailRef.current.show(record)}>查看</a>
        </Space>
      )
    },
  ];
  return (
    <div className="student-management">
      <AddOrEdit onRef={editRef} title='编辑实习记录' refresh={() => handleTableChange(grade)} />
      <AddOrEdit onRef={addRef} title='添加实习记录' refresh={() => handleTableChange(grade)}  />
      <Detail onRef={detailRef} />
      <PageHeader
        className="header"
        ghost={false}
        title="学生管理"
        breadcrumb={{ routes }}
      />
      <Row
        gutter={10}
        justify="center"
        style={{
          marginTop: 20,
        }}
      >
        <Col span={23}>
          <Card>
            <Row justify='space-between' style={{margin: '16px 0'}}>
              <Col>
                <Row align='middle'>
                  <span style={{marginRight: 8}}>年级</span>
                  <Select value={grade} style={{ width: 120 }} onChange={(val) => {
                    setGrade(val);
                    handleTableChange(val)
                  }}>
                    {grades.map(grade => (
                      <Option key={grade} value={grade}>{grade}</Option>
                    ))}
                  </Select>
                </Row>
              </Col>
              <Col>
                <Row justify='space-between' style={{width: '200px'}}>
                  <Button icon={<PlusOutlined />} onClick={() => addRef.current.add()}>
                    添加
                  </Button>
                  <Button type="primary" icon={<CloudDownloadOutlined />}>
                    导出
                  </Button>
                </Row>
              </Col>
            </Row>
            <Table
              columns={columns}
              rowKey={(record: any) => record.student_id}
              dataSource={internship}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default StudentManagement;
