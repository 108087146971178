import React, { useImperativeHandle, useState } from 'react';
import { Drawer, Descriptions, Tabs, Card, AutoComplete, Col, Row, Button, notification } from 'antd';
import { REPORT_STATUS_MAP } from '@/constant';
import { commentWeekReport } from '@/service/weekReport'
import './index.less'
import 'braft-editor/dist/output.css'

interface Props {
  onRef: any;
  refresh?: Function;
  showComment: boolean
}

const { TabPane } = Tabs

const Read: React.FC<Props> = (props) => {
  const { showComment } = props
  const [visible, setVisible] = useState<boolean>(false);
  const [report, setReport] = useState<any>({})
  const [comment, setComment] = useState<string>('很棒！')
  useImperativeHandle(props.onRef, () => {
    return {
      show,
    };
  });
  const show = (_report: any) => {
    setReport(_report)
    setVisible(true);
    setComment(_report?.comment)
  };
  const close = () => {
    setVisible(false);
  };
  const onSubmitComment = async () => {
    if (!comment) {
      notification.warn({message: '请输入评语'})
      return
    }
    commentWeekReport({ id: report.id, comment }).then(res => {
      console.log(res);
      notification.success({message: '批阅成功'})
    })
  }
  return (
    <Drawer
      placement="right"
      width='40%'
      closable={false}
      onClose={close}
      visible={visible}
      className='read'
    >
      <Tabs defaultActiveKey="base">
        <TabPane tab="基本内容" key="base">
          <Descriptions column={2} bordered>
            <Descriptions.Item label="姓名">{report.student}</Descriptions.Item>
            <Descriptions.Item label="学号">{report.student_id}</Descriptions.Item>
            <Descriptions.Item label="实习单位">{report.company}</Descriptions.Item>
            <Descriptions.Item label="周次">{report.week}</Descriptions.Item>
            <Descriptions.Item label="起止时间">{report.start_at} ~ {report.end_at}</Descriptions.Item>
            <Descriptions.Item label="提交时间">{report.submit_at}</Descriptions.Item>
            <Descriptions.Item label="状态">{report.status && REPORT_STATUS_MAP[report.status].name}</Descriptions.Item>
            <Descriptions.Item label="备注">{report.tip}</Descriptions.Item>
          </Descriptions>
          <Card title='周报内容' style={{marginTop: '16px'}}>
            <div className="braft-output-content" dangerouslySetInnerHTML={{__html: report.content}}/>
          </Card>
        </TabPane>
        <TabPane tab="周报附件" key="pdf">
          <div>
            { report.pdf ? <iframe
              className='doc-reader'
              src={`${report.pdf}?ci-process=doc-preview&dstType=html`}
            /> : <div>该份周报没有上传附件</div> }
          </div>
        </TabPane>
      </Tabs>
      {
        showComment && <Row justify='space-between' className='comment'>
          <Col span={12}>
            <AutoComplete
              placeholder='请输入评语'
              className='comment-input'
              allowClear
              bordered
              options={[{value: '很棒！'}, {value: '还需努力！'}]}
              onSelect={(val) => {setComment(val)}}
              onClear={() => setComment('')}
              value={comment}
            />
          </Col>
          <Col>
            <Button type='primary' onClick={onSubmitComment}>批阅</Button>
          </Col>
        </Row>
      }
    </Drawer>
  );
};

export default Read
