import React, {useState, useEffect} from 'react';
import '@/pages/Main/WeeklyNewspaper/index.less';
import {
    PageHeader,
    Row,
    Col,
    Card,
    Table,
    DatePicker,
    Select,
} from 'antd';
import {StatusInterface, weeklyNewsRequestParams} from '@/models/weeklyNews';
import {getWeeklyNews} from '@/service/weeklyNews';

import {PAGE_SIZE} from '@/constant/weeklyNews';
import {REPORT_STATUS_MAP} from "@/constant";
import {
    Redirect, Route, Switch, NavLink,
} from 'react-router-dom';
import Success from '@/pages/Main/WeeklyNewspaper/components/Success';
import Edit from '@/pages/Main/WeeklyNewspaper/components/Edit';
import View from '@/pages/Main/WeeklyNewspaper/components/View';
import {WeeklyNews} from '@/models/weeklyNews';
import { HeaderRouter } from '@/models/router';
import Read from '@/pages/Main/ReadOver/component/read';

const {RangePicker} = DatePicker;
const {Option} = Select;
const Weeks: Array<number | null> = [null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,];
const Status: Array<StatusInterface> = [
    {
        number: null,
        status: '全部周报',
    },
    {
        number: 1,
        status: '未提交',
    },
    {
        number: 2,
        status: '已提交',
    },
    {
        number: 3,
        status: '已逾期',
    },
    {
        number: 4,
        status: '已补交',
    },
];

export const routes: HeaderRouter[] = [
    {
        path: '/Main/Home',
        breadcrumbName: '首页',
    },
    {
        path: '/Main/WeeklyNewspaper',
        breadcrumbName: '周报',
    },
];


const Home: React.FC = () => {
    const [date, setDate] = useState<Array<string>>();
    const [week, setWeek] = useState<number | null>(null);
    const [status, setStatus] = useState<number | null>(null);
    const [tableDataSource, setTableDataSource] = useState<Array<WeeklyNews>>();
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const readRef = React.createRef<any>()

    const columns = [
        {
            title: '实习单位',
            dataIndex: 'company',
            key: 'company',
            align: 'center',
        },
        {
            title: '实习周次',
            dataIndex: 'week',
            key: 'week',
            align: 'center',
        },
        {
            title: '起止时间',
            dataIndex: 'start_at',
            key: 'start_at',
            align: 'center',
            render: (time: string, record: WeeklyNews) => (
              <span>{`${time} 至 ${record.end_at}`}</span>
            ),
        },
        {
            title: '提交时间',
            dataIndex: 'submit_at',
            key: 'submit_at',
            align: 'center',
            render: (time: any) => <span>{time.slice(5)}</span>,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (status: any) => {
                return (
                  <div className="news-status">
                      <div
                        style={{
                            backgroundColor: REPORT_STATUS_MAP[status].color,
                        }}
                      />
                      {REPORT_STATUS_MAP[status].name}
                  </div>
                );
            },
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '',
            render: (id: any, record: any) => (
              <div className="news-link">
                  <NavLink
                    to={`/Main/WeeklyNewspaper/Edit/${id}`}
                    style={{marginRight:18}}
                  >
                      编辑
                  </NavLink>
                  <a
                    onClick={() => readRef.current.show(record)}
                  >
                      查看
                  </a>
              </div>
            ),
        },
    ];

    const getNews = async () => {
        const params: weeklyNewsRequestParams = {
            begin: date ? date[0] : undefined,
            end: date ? date[1] : undefined,
            page_size: PAGE_SIZE,
            week,
            status,
            page,
        };
        const {data} = await getWeeklyNews(params);
        setTotal(data.total);
        setTableDataSource(data.list);
    };

    useEffect(() => {
        getNews();
    }, [week, date, status, page]);

    return (
        <div className="weekly-news">
            <Read onRef={readRef} showComment={false} />
            <PageHeader
              style={{marginTop: 2}}
                className="header"
                ghost={false}
                title="周报"
                breadcrumb={{routes}}
            />
            <Row>
                <Col span={24}>
                    <Card className="card">
                        <Row className="input">
                            <div>
                                <span>日期范围</span>
                                <RangePicker
                                    onChange={(date, dateString) => {
                                        setDate(dateString);
                                    }}
                                    placeholder={['请选择开始日期', '请选择结束日期']}
                                />
                            </div>
                            <div>
                                <span>周次查询</span>
                                <Select
                                    defaultValue={null}
                                    onChange={(val) => {
                                        setWeek(val);
                                    }}
                                >
                                    {Weeks.map((val) => (
                                        <Option value={val} key={val}>
                                            {val ? `第${val}周` : '全部周次'}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <span>周报状态</span>
                                <Select
                                    defaultValue={null}
                                    onChange={(val) => {
                                        setStatus(val);
                                    }}
                                >
                                    {Status.map((val) => (
                                        <Option value={val.number} key={val.number}>
                                            {val.status}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Row>
                        <Table
                            columns={columns}
                            dataSource={tableDataSource}
                            pagination={{
                                pageSize: PAGE_SIZE,
                                total,
                                onChange: (val) => {
                                    setPage(val);
                                },
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const WeeklyNewspaper: React.FC = () => (
    <>
        <Switch>
            <Route path="/Main/WeeklyNewspaper/Home" component={Home}/>
            <Route path="/Main/WeeklyNewspaper/Success/:id" component={Success}/>
            <Route path="/Main/WeeklyNewspaper/Edit/:id" component={Edit}/>
            <Route path="/Main/WeeklyNewspaper/View/:id" component={View}/>
            <Redirect
                from="/Main/WeeklyNewspaper"
                to="/Main/WeeklyNewspaper/Home"
            />
        </Switch>
    </>
);

export default WeeklyNewspaper;
