export const refresh = () => {
  setTimeout(() => {
    window.location.reload();
  }, 500);
};

export const fileDownload = (url: string, fileName: string = '') => {
  const aTag = document.createElement('a');
  if (!fileName) {
    fileName = url.split('/').slice(-1)[0]
  }
  aTag.download = fileName;
  aTag.href = url;
  aTag.style.display = 'none'
  aTag.target = '_blank'
  document.body.appendChild(aTag)
  aTag.click();
  document.body.removeChild(aTag)
}
