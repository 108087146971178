import React, {useEffect, useState} from 'react';
import '@/pages/Main/PersonalCenter/component/Message/index.less';
import {
    Card,
    Col,
    List,
    Row
} from 'antd';
import { getMessages } from '@/service/user'

const Message: React.FC = () => {
    const [page, setPage] = useState<number>(1)
    const [page_size, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0)
    const [messages, setMessages] = useState<any[]>()
    useEffect(() => {
        onLoad();
    }, []);
    const onLoad = async () => {
        getMessageList({})
    }
    const getMessageList = async (params: object) => {
        const {data} = await getMessages({
            page_size,
            page,
            ...params
        })
        setMessages(data.list)
        setTotal(data.total)
    }
    return (
        <Card>
            <List
            dataSource={messages}
            split
            renderItem={item => <List.Item>
            <List.Item.Meta
                title={item.content}
            />
            <Row justify='end'>
                <Col style={{color: '#777'}}>{item.created_at}</Col>
            </Row>
            </List.Item>}
            pagination={{
                pageSize: page_size,
                current: page,
                total: total,
                onChange: (page, page_size) => {
                    page_size && setPageSize(page_size)
                    setPage(page)
                    getMessageList({page, page_size})
                }
            }}
            />
        </Card>
    );
}

export default Message;
