import React, { ReactNode, useEffect, useState } from 'react';
import '@/pages/Main/index.less';
import { Menu, Layout, notification } from 'antd';
import {
  AppstoreOutlined,
  FormOutlined,
  TeamOutlined,
  ProfileOutlined,
  NotificationOutlined,
  BankOutlined,
  SendOutlined,
  IdcardOutlined,
  LineChartOutlined,
  UserOutlined,
  SettingOutlined,
  AuditOutlined,
  ReconciliationOutlined,
  PicRightOutlined,
  BookOutlined
} from '@ant-design/icons';
import Announcement from '@/pages/Main/Announcement';
import JobSquare from '@/pages/Main/JobSquare';
import Discuss from '@/pages/Main/Discuss';
import WeeklyNewspaper from '@/pages/Main/WeeklyNewspaper';
import Guide from '@/pages/Main/Guide';
import Home from '@/pages/Main/Home';
import MainHeader from '@/pages/Main/components/MainHeader';
import ReadOver from '@/pages/Main/ReadOver';
import StudentManagement from '@/pages/Main/StudentManagement';
import PublishJob from '@/pages/Main/PublishJob';
import ResumeCollect from '@/pages/Main/ResumeCollect';
import MyDeliver from '@/pages/Main/MyDeliver';
import PersonalCenter from '@/pages/Main/PersonalCenter';
import Settings from '@/pages/Main/Settings';
import UserManager from '@/pages/Main/admin/user';
import CollegeManager from '@/pages/Main/admin/college';
import MajorManager from '@/pages/Main/admin/major';
import CompanyManager from '@/pages/Main/admin/company';
import InternshipManager from '@/pages/Main/admin/internship';
import AnnounceManager from '@/pages/Main/admin/announce';
import { NavLink, Route, Switch } from 'react-router-dom';
import { setUser } from '@/store/actions/User';
import { connect } from 'react-redux';
import { User } from '@/models/user';
import { Service } from '@/models/service';
import { getUser } from '@/service/user';
import { UserRole } from '@/utils/constant';

const {
  Header,
  Content,
  Footer,
  Sider,
} = Layout;

interface router {
  path: string,
  title: string,
  key?: string,
  component: React.FC,
  icon: ReactNode,
  roles: string[],
}

const allRoutes: router[] = [
  {
    path: '/Home',
    title: '首页',
    component: Home,
    icon: <AppstoreOutlined/>,
    roles: [
      UserRole.All,
    ]
  },
  {
    path: '/JobSquare',
    title: '职位广场',
    component: JobSquare,
    icon: <BankOutlined/>,
    roles: [
      UserRole.All,
    ]
  },
  // {
  //   path: '/Discuss',
  //   title: '讨论 / 面经',
  //   component: Discuss,
  //   icon: <ProfileOutlined/>,
  //   roles: [
  //     UserRole.All,
  //   ]
  // },
  {
    path: '/Announcement',
    title: '公告',
    component: Announcement,
    icon: <NotificationOutlined/>,
    roles: [
      UserRole.All,
    ]
  },
  {
    path: '/Guide',
    title: '求职指南',
    component: Guide,
    icon: <LineChartOutlined/>,
    roles: [
      UserRole.All,
    ]
  },
  {
    path: '/MyDeliver',
    title: '我的投递',
    component: MyDeliver,
    icon: <AuditOutlined/>,
    roles: [
      UserRole.Student,
    ]
  },
  {
    path: '/WeeklyNewspaper',
    key: '/WeeklyNewspaper/Home',
    title: '周报',
    component: WeeklyNewspaper,
    icon: <FormOutlined/>,
    roles: [
      UserRole.Student,
    ]
  },
  {
    path: '/ReadOver',
    title: '周报批阅',
    component: ReadOver,
    icon: <FormOutlined/>,
    roles: [
      UserRole.Teacher,
    ]
  },
  {
    path: '/StudentManagement',
    title: '学生管理',
    component: StudentManagement,
    icon: <TeamOutlined/>,
    roles: [
      UserRole.Teacher,
    ]
  },
  {
    path: '/PublishJob',
    title: '发布职位',
    component: PublishJob,
    icon: <SendOutlined/>,
    roles: [
      UserRole.HR,
    ]
  },
  {
    path: '/ResumeCollect',
    title: '简历收集',
    component: ResumeCollect,
    icon: <IdcardOutlined/>,
    roles: [
      UserRole.HR,
    ]
  },
  {
    path: '/PersonalCenter',
    title: '个人中心',
    component: PersonalCenter,
    icon: <UserOutlined/>,
    roles: [
      UserRole.Student,
    ]
  },
  {
    path: '/admin/user',
    title: '用户管理',
    component: UserManager,
    icon: <UserOutlined/>,
    roles: [
      UserRole.Superuser,
    ]
  },
  {
    path: '/admin/college',
    title: '学院管理',
    component: CollegeManager,
    icon: <BankOutlined/>,
    roles: [
      UserRole.Superuser,
    ]
  },
  {
    path: '/admin/major',
    title: '专业管理',
    component: MajorManager,
    icon: <ReconciliationOutlined/>,
    roles: [
      UserRole.Superuser,
    ]
  },
  {
    path: '/admin/company',
    title: '企业管理',
    component: CompanyManager,
    icon: <PicRightOutlined />,
    roles: [
      UserRole.Superuser,
    ]
  },
  {
    path: '/admin/internship',
    title: '实习管理',
    component: InternshipManager,
    icon: <BookOutlined />,
    roles: [
      UserRole.Superuser,
    ]
  },
  {
    path: '/admin/announce',
    title: '公告管理',
    component: AnnounceManager,
    icon: <NotificationOutlined />,
    roles: [
      UserRole.Superuser,
    ]
  },
  {
    path: '/Settings',
    title: '设置',
    component: Settings,
    icon: <SettingOutlined/>,
    roles: [
      UserRole.All,
    ]
  },
];
const Main: React.FC = (props: any) => {
  const pathName = props.history.location.pathname;
  const { setUser } = props;
  const [routers, setRouters] = useState<router[]>([]);
  const onLoad = async () => {
    getUser()
      .then((userData: Service) => {
        setUser(userData.data);
        const user: User = userData.data;
        let r: router[] = [];
        for (let i = 0; i < allRoutes.length; i++) {
          if (allRoutes[i].roles.indexOf(UserRole.All) !== -1 || allRoutes[i].roles.indexOf(user.role) !== -1) {
            r.push(allRoutes[i]);
          }
        }
        setRouters(r);
      })
      .catch(e => {
        console.log(e);
        notification.error({message: '用户登录过期'})
        window.location.href = '/';
      });
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <Layout style={{
      height: '100vh',
      overflowX: 'hidden'
    }}>
      <Sider>
        <div className="title-container">
          <div className="title">
            深圳技术大学
          </div>
          <div className="title">
            企业实习管理系统
          </div>
        </div>
        <Menu
          className="menu"
          defaultSelectedKeys={['Main/Home']}
          selectedKeys={[pathName]}
          mode="inline"
          theme="dark"
        >
          {routers.map((route) => (
            <Menu.Item key={`/Main${route.key || route.path}`} icon={route.icon}>
              <NavLink className="router-title" to={`/Main${route.path}`}>
                {' '}
                {route.title}
              </NavLink>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header className="site-layout-background" style={{
          padding: 0,
          background: 'white',
          maxHeight: 60,
          marginBottom: 2
        }}>
          <MainHeader/>
        </Header>
        <Content style={{
          overflowY: 'scroll',
          maxHeight: 'calc(100vh-60px)',
          overflowX: 'hidden'
        }}>
          <Switch>
            {
              routers.map((route) => (
                <Route
                  key={route.path}
                  path={`/Main${route.path}`}
                  component={route.component}
                />
              ))
            }
          </Switch>
          <Footer style={{ textAlign: 'center' }}>深圳技术大学实习管理系统 ©2021 Created by BDI</Footer>
        </Content>
      </Layout>
    </Layout>

  );
};
const mapStateToProps = (state: { User: User; }) => ({ user: state.User });

const mapDispatchToProps = { setUser };
export default connect(mapStateToProps, mapDispatchToProps)(Main);
