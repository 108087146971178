import React, { useState, useEffect } from 'react';
import { Row, Col, Avatar, Menu, Dropdown, Badge, List, Card } from 'antd'
import { LogoutOutlined, SettingOutlined, BellOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { setUser } from '@/store/actions/User';
import { User } from '@/models/user';
import { getMessages } from '@/service/user'
import '@/pages/Main/components/MainHeader/index.less';

const MainHeader: React.FC<{ user: User, history: any }> = (props) => {
  const { user } = props
  const [showDot, setShowDot] = useState<boolean>(true)
  const [messages, setMessages] = useState<any[]>()
  useEffect(() => {
      onLoad();
  }, []);
  const onLoad = async () => {
    setInterval(() => {
      getMessageList({})
    }, 5000)
  }
  const getMessageList = async (params: object) => {
      const {data} = await getMessages({
          page_size: 10,
          page: 1,
          ...params
      })
      setMessages(data.list)
  }

  const menuItemClick = (e: any) => {
    switch (e.key) {
      case '/Main/Settings':
        props.history.push(e.key);
        break;
      case 'logout':
        sessionStorage.removeItem('token');
        props.history.push('/');
        break;
      default:
        break;
    }
  }
  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} onClick={menuItemClick}>
      <Menu.Item key="/Main/Settings">
        <SettingOutlined />
        个人设置
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined />
        退出登录
      </Menu.Item>
    </Menu>
  );
  const messageDropdown = <Card>
    <List
    dataSource={messages}
    size='large'
    style={{
      width: '300px',
      maxHeight: '400px',
      overflowY: 'visible'
    }}
    renderItem={item => <List.Item>
      <List.Item.Meta
          title={item.content}
          description={item.created_at.split(' ')[0]}
      />
    </List.Item>}
  />
    </Card>
  return (
    <div className="main-header">
      <Row justify='end' gutter={16}>
        <Dropdown overlay={messageDropdown} onVisibleChange={() => setShowDot(false)}>
          <Col>
          <Badge dot={showDot}>
            <Avatar icon={<BellOutlined />} size={32} style={{background: '#fff', color: '#777'}}/>
          </Badge>
          </Col>
        </Dropdown>
        <Dropdown overlay={menuHeaderDropdown}>
          <Col>
            <Avatar src={user.avatar} size={32}/>
            <span style={{margin: '0 16px 0 10px', fontSize: '16px', color: '#333'}}>{user.username}</span>
          </Col>
        </Dropdown>
      </Row>
    </div>
  );
}

const mapStateToProps = (state: { User: User; }) => ({ user: state.User });

const mapDispatchToProps = { setUser };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainHeader));

