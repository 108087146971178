import React from 'react';
import {
  Empty
} from 'antd'
import '@/pages/Main/Discuss/index.less';

const Guide: React.FC = () => {
  return (
    <div>
      <Empty
        description='功能正在开发'
        style={{marginTop: 16}}
      />
    </div>
  );
}

export default Guide;
