import {Service} from '@/models/service';
import {request} from './index';

export const getUsers = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/user/`,
  { data }
);

export const changeUser = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/user/change/`,
  { data }
);

export const createUser = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/user/create/`,
  { data }
);

export const bulkCreateUser = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/user/bulk_create/`,
  { data }
);

export const removeUser = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/user/remove/`,
  { data }
);



export const getColleges = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/college/`,
  { data }
);

export const changeCollege = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/college/change/`,
  { data }
);

export const createCollege = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/college/create/`,
  { data }
);

export const removeCollege = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/college/remove/`,
  { data }
);


export const getMajors = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/major/`,
  { data }
);

export const changeMajor = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/major/change/`,
  { data }
);

export const createMajor = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/major/create/`,
  { data }
);

export const removeMajor = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/major/remove/`,
  { data }
);


export const getCompanies = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/company/`,
  { data }
);

export const changeCompany = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/company/change/`,
  { data }
);

export const createCompany = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/company/create/`,
  { data }
);

export const removeCompany = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/company/remove/`,
  { data }
);


export const getInternships = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/internship/`,
  { data }
);

export const changeInternship = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/internship/change/`,
  { data }
);

export const createInternship = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/internship/create/`,
  { data }
);

export const removeInternship = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/internship/remove/`,
  { data }
);

export const getAnnounces = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/announce/`,
  { data }
);

export const changeAnnounce = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/announce/change/`,
  { data }
);

export const createAnnounce = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/announce/create/`,
  { data }
);

export const removeAnnounce = async (data: object): Promise<Service> => request(
  'POST',
  `/superuser/announce/remove/`,
  { data }
);
