import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Drawer, PageHeader, Row, Select, Space, Table } from 'antd';
import '@/pages/Main/MyDeliver/index.less';
import { getDeliversForStudent } from '@/service/company'
import { Detail } from '@/pages/Main/PublishJob/component/Detail'

const routes: any[] = [
  {
    path: '/Main/Home',
    breadcrumbName: '首页',
  },
  {
    path: '/Main/MyDeliver',
    breadcrumbName: '我的投递',
  },
];

const MyDeliver: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [page_size, setPageSize] = useState<number>(10);
  const [delivers, setDelivers] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>();
  const [pdf, setPdf] = useState<any>(null);
  const detailRef = React.createRef<any>();
  const onLoad = async () => {
    getDelivers({})
  };
  useEffect(() => {
    onLoad();
  }, []);
  const columns = [
    {
      title: '公司',
      dataIndex: 'company',
    },
    {
      title: '应聘职位',
      dataIndex: 'position',
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => {
            detailRef.current.show(record.position_info)
          }}>{record.position}</a>
        </Space>
      )
    },
    {
      title: '投递时间',
      dataIndex: 'created_at',
    },
    {
      title: '查看时间',
      dataIndex: 'updated_at',
      render: (_: any, record: any) => (
        <Space size="middle">
          <span>{record.status === '未查看' ? '--' : record.updated_at}</span>
        </Space>
      )
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (_: any, record: any) => (
        <Space size="middle">
          <span style={{color: record.status === '未查看' ? 'red' : '#6BA369'}}>{record.status}</span>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'id',
      width: '10%',
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => {
            setVisible(true)
            setPdf(record.resume)
          }}>查看简历</a>
        </Space>
      )
    },
  ];

  const getDelivers = async (params: object) => {
    setLoading(true)
    const {data} = await getDeliversForStudent(params)
    setDelivers(data.list)
    setLoading(false)
  }

  return (
    <div>
      <Detail onRef={detailRef}/>
      <PageHeader
          className="header"
          ghost={false}
          title="我的投递"
          breadcrumb={{ routes }}
        />
        <Drawer
          visible={visible}
          onClose={() => setVisible(false)}
          placement="right"
          width='40%'
          closable={false}
        >
          <iframe
            style={{width: '100%', minHeight: '95vh', border: 'none'}}
            src={`${pdf}?ci-process=doc-preview&dstType=html`}
          />
        </Drawer>
        <Row
          gutter={10}
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Col span={23}>
            <Card>
              <Table
                columns={columns}
                rowKey={(record: any) => record.student_id}
                dataSource={delivers}
                loading={loading}
                pagination={{
                  pageSize: page_size,
                  current: page,
                  showSizeChanger: true,
                  total: total,
                  onChange: (page: number, page_size: number) => {
                    setPage(page)
                    setPageSize(page_size)
                    getDelivers({page, page_size})
                  }
                }}
              />
            </Card>
          </Col>
        </Row>
    </div>
  );
}

export default MyDeliver;
