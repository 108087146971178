import React, {useState, useEffect} from 'react';
import { Tabs, Card, Form, Input, Button, Image, Modal, notification, Upload } from 'antd'
import '@/pages/Main/Settings/index.less';
import { connect } from 'react-redux';
import { UserRole } from '@/utils/constant'
import { changePassword } from '@/service/user'
import { getCompanyForHR, changeCompanyInfo } from '@/service/company'
import { upLoadFile } from '@/service/cos';

const { TabPane } = Tabs

const Settings: React.FC<{user: any}> = (props) => {
  const { user } = props
  const [isEditCompany, setIsEditCompany] = React.useState<boolean>(false)
  const [company, setCompany] = React.useState<any>()
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false)
  const [companyForm] = Form.useForm()
  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 12 },
  };
  const onLoad = async () => {
    if (user.role === UserRole.HR) {
      await getCompanyInfo()
    }
  };
  useEffect(() => {
    onLoad();
  }, []);

  const UploadProps = {
    name: 'file',
    multiple: false,
    fileList: [],
    customRequest: async (info: any) => {
      const { file } = info;
      const url: string = await upLoadFile(file, 'company/logo');
      notification.info({message: '上传成功'})
      setCompany({
        ...company,
        logo: url
      })
      changeCompanyInfo({
        logo: url,
        company_id: company.id
      })
    },
    onRemove() {
    },
    accept: '.jpg,.png'
  };

  const onFinish = async (values: any) => {
    if (!isEditCompany) {
      setIsEditCompany(true)
    } else {
      // 提交表单
      console.log(values);
      setIsEditCompany(false)
      const {data} = await changeCompanyInfo({
        ...values,
        company_id: company.id
      })
      notification.success({message: data})
    }
  }
  const changeUserPassword = async (values: any) => {
    console.log(values);
    if (values.new_password !== values.repeat_password) {
      notification.error({message: '新密码与重复密码不一致'})
      return
    }
    if (values.new_password.length < 8) {
      notification.error({message: '密码不能小于8位'})
      return
    }
    const { msg } = await changePassword(values)
    notification.success({message: msg})
    setShowChangePassword(false)
  }
  const getCompanyInfo = async () => {
    const {data} = await getCompanyForHR({})
    setCompany(data)
    companyForm.setFieldsValue(data)
  }
  return <div className='settings'>
    <Modal
    visible={showChangePassword}
    footer={null}
    onCancel={() => setShowChangePassword(false)}
    mask={true}
    title='修改密码'
    >
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} name="reset-password" onFinish={changeUserPassword}>
        <Form.Item name='old_password' label="旧密码" rules={[{ required: true }]}>
          <Input style={{marginRight: '16px'}} type='password' placeholder='请输入旧密码'/>
        </Form.Item>
        <Form.Item name='new_password' label="新密码" rules={[{ required: true }]}>
          <Input style={{marginRight: '16px'}} type='password' placeholder='请输入新密码'/>
        </Form.Item>
        <Form.Item name='repeat_password' label="确认密码" rules={[{ required: true }]}>
          <Input style={{marginRight: '16px'}} type='password' placeholder='请重复输入'/>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 5 }}>
          <Button type="primary" htmlType="submit">
            修改密码
          </Button>
        </Form.Item>
      </Form>
    </Modal>
    <Card style={{ margin: '16px', minHeight: '50vh' }}>
      <Tabs tabPosition='left'>
        {
          user?.role === UserRole.HR && <TabPane tab="公司信息" key="company">
          <div className='logo'>
            <Image width={200} src={company?.logo}/>
            <Upload {...UploadProps}>
              <Button className='btn'>更换Logo</Button>
            </Upload>
          </div>
          <Form {...layout} name="company_form" onFinish={onFinish} form={companyForm}>
            <Form.Item name='name' label="企业名称" rules={[{ required: true }]}>
              <Input style={{width: '50%'}} disabled={!isEditCompany}/>
            </Form.Item>
            <Form.Item name='address' label="企业地址" rules={[{ required: true }]}>
              <Input disabled={!isEditCompany}/>
            </Form.Item>
            <Form.Item name='website' label="企业官网" rules={[{ required: true }]}>
              <Input disabled={!isEditCompany}/>
            </Form.Item>
            <Form.Item name='description' label="企业简介" rules={[{ required: true }]}>
              <Input.TextArea rows={10} disabled={!isEditCompany}/>
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 2 }}>
              <Button type="primary" htmlType="submit">
                { isEditCompany ? '提交' : '编辑' }
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        }
        <TabPane tab="安全设置" key="safe">
          <Form {...layout} name="nest-messages" onFinish={onFinish}>
            <Form.Item name='phone' label="绑定手机">
              <Input style={{width: '50%', marginRight: '16px'}} disabled />
              <Button>换绑手机</Button>
            </Form.Item>
            <Form.Item name='password' label="密码">
              <Input style={{width: '50%', marginRight: '16px'}} type='password' defaultValue='******' disabled />
              <Button onClick={() => setShowChangePassword(true)}>修改密码</Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </Card>
  </div>
}

const mapStateToProps = (state: { User: object; }) => ({ user: state.User });

export default connect(mapStateToProps)(Settings);
