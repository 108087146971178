import React from 'react';
import '@/pages/Register/index.less';

const Register: React.FC = () => (
  <div>
    注册
  </div>
);

export default Register;
