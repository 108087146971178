import React, {useRef, useState} from 'react';
import '@/pages/Main/PersonalCenter/component/ResumeModal/index.less';
import {
    Modal, Form, Input, Button, message,
} from 'antd';
import {upLoadFile} from '@/service/cos';
import {addResume} from '@/service/resume';
import {refresh} from '@/utils';

interface ResumeModalProps {
    title?: string;
    isShowResumeModal?: boolean;
    close?: any;
}

const ResumeModal: React.FC<ResumeModalProps> = (props) => {
    const {title, isShowResumeModal, close} = props;

    const resumeRef = useRef<HTMLInputElement>();
    const otherRef = useRef<HTMLInputElement>();
    const [resumeName, setResumeName] = useState<string>('');
    const [resumeFileName, setResumeFileName] = useState<string>('');
    const [otherFileName, setOtherFileName] = useState<string>('');

    const closeModal = () => {
        close();
        setResumeFileName('');
        setOtherFileName('');
        setResumeName('');
    };

    const selectResume = () => {
        // @ts-ignore
        resumeRef.current.click();
    };

    const selectOther = () => {
        // @ts-ignore
        otherRef.current.click();
    };

  const onSubmit = async () => {
    try {
      // @ts-ignore
      const resume = resumeRef.current.files[0] ? resumeRef.current.files[0] : null;
      // @ts-ignore
      const other = otherRef.current.files[0] ? otherRef.current.files[0] : null;

            let otherUrl: string = '';

            const resumeUrl = await upLoadFile(resume, 'resume');

            if (other) {
                otherUrl = await upLoadFile(other, 'resume');
            }

      await addResume(resumeName, resumeUrl, otherUrl);
      closeModal();
      message.success('上传成功！');
      refresh();
    } catch (e) {
      message.error(`${e}上传失败！`);
    }
  };
  return (
    <Modal
      className="resume-modal"
      title={title}
      visible={isShowResumeModal}
      onCancel={closeModal}
      onOk={onSubmit}
      cancelText="取消"
      okText="上传"
      destroyOnClose
    >
      <Form name="basic" initialValues={{}}>
        <Form.Item
          label="简历名称"
          name="resumeName"
          rules={[
            { required: true, message: '请填写次简历名称，如"xxx-开发工程师"' },
          ]}
        >
          <Input
            value={resumeName}
            placeholder='请填写次简历名称，如"xxx-开发工程师"'
            onChange={(val) => {
              setResumeName(val.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="简历" name="resumeFile" rules={[{ required: true }]}>
          <div className="item">
            <Button onClick={selectResume} type="primary">
              选择简历
            </Button>
            <input
              type="file"
              ref={resumeRef}
              style={{ display: 'none' }}
              onChange={(e) => {
                // @ts-ignore
                setResumeFileName(e.target.files[0].name);
              }}
            />
            <div className="text">{resumeFileName}</div>
          </div>
        </Form.Item>
        <Form.Item label="其他材料" name="otherFile">
          <div className="item">
            <Button onClick={selectOther} type="primary">
              选择其他材料
            </Button>
            <input
              type="file"
              ref={otherRef}
              style={{ display: 'none' }}
              onChange={(e) => {
                // @ts-ignore
                setOtherFileName(e.target.files[0].name);
              }}
            />
            <div className="text">{otherFileName}</div>
          </div>
        </Form.Item>
        <div
          style={{
            fontSize: 14,
            color: 'rgba(0,0,0,0.45)',
          }}
        >
          提示：简历使用pdf文件，大小限制10M以内
          <br />
          其他资料可选择性上传，如作品集等
        </div>
      </Form>
    </Modal>
  );
};

export default ResumeModal;
