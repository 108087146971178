interface envMap {
  DEV: number;
  TEST: number;
  PROD: number;
}

const ENV_MAP: envMap = {
  // 开发环境
  DEV: 0,
  // 测试环境
  TEST: 1,
  // 生产环境
  PROD: 2,
};
const ENV: number = ENV_MAP.DEV;

const BUCKET: string = 'internship-static-1259072275';
const REGION = 'ap-guangzhou';

const REPORT_STATUS_MAP: any = {
  1: {
    name: '未提交',
    color: '#ff4d4f'
  },
  2: {
    name: '已提交',
    color: '#52c41a'
  },
  3: {
    name: '已逾期',
    color: '#ff4d4f'
  },
  4: {
    name: '已补交',
    color: '#52c41a'
  }
};

export {
  ENV, ENV_MAP, BUCKET, REGION, REPORT_STATUS_MAP
};
