import {request} from '@/service/index';
import COS from 'cos-js-sdk-v5';
import {BUCKET, REGION} from '@/constant';

export const getCosAuth = (options: any, callback: any) => {
    request('GET', '/common/cos-auth/').then((res: any) => {
        const {data} = res;
        const {credentials} = data;
        callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            XCosSecurityToken: credentials.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: data.expiredTime, //
        });
    });
};

export const upLoadFile = async (file: any, folder: string): Promise<string> => new Promise((resolve, reject) => {
    if (!file) return;
    const cos: any = new COS({
        getAuthorization: getCosAuth,
    });

    cos.putObject(
        {
            Bucket: BUCKET /* 必须 */,
            Region: REGION /* 存储桶所在地域，必须字段 */,
            Key: `/${folder}/${(new Date()).getTime()}_${file.name}` /* 必须 */,
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress(progressData: any) {
                // console.log(progressData);
            },
        },
        (err: Error, data: any) => {
            if (err) {
                console.error(err);
                reject(err);
            } else {
                resolve('https://' + data.Location);
            }
        },
    );
});
