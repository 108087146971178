import { combineReducers } from 'redux';
import Count from './Count';
import Person from './Person';
import User from './User';

export default combineReducers({
  Count,
  Person,
  User
});
