import React, {useEffect, useState} from 'react';
import "./index.less"
import {useParams} from "react-router-dom";
import {WeeklyNews} from '@/models/weeklyNews';
import {getNewsById} from '@/service/weeklyNews';
import { Button } from 'antd'

const View: React.FC = (props) => {
    const params: any = useParams();
    const {id} = params;

    const [news, setNews] = useState<WeeklyNews>()

    const onLoad = async () => {
        const {data} = await getNewsById(id);
        setNews(data)
    }

    const edit = () => {
        props.history.push(`/Main/WeeklyNewspaper/Edit/${news?.id}`)
    }

    useEffect(() => {
        onLoad()
    }, [])

    return (
        <div className='weekly-news-card'>
            <div className='title'>{news?.company}</div>
            <div className='time'>最近提交时间{news?.updated_at}</div>
            <div className='info'>
                <div>实习周次：<span>第{` ${news?.week} `}周次</span></div>
                <div>日期：<span>{`${news?.start_at} ~ ${news?.end_at}`}</span></div>
                <div>备注（选填）：<span>{news?.tip}</span></div>
                <div>周报文件：{news?.pdf ? <a href={`https://${news?.pdf}`} target='_blank' rel="noreferrer">周报文件</a> : '无'}</div>
                <div>周报在线编辑：</div>
                <div dangerouslySetInnerHTML={{__html: news?.content}} className='content'/>
                {
                    news?.comment  ? <div>教师评语：<span>{news?.comment}</span></div> : ''
                }
            </div>
            <div className='btns'>
                <Button onClick={edit}>编辑</Button>
            </div>
        </div>
    )
}

export default View;